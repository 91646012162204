
import { defineComponent } from 'vue';
// eslint-disable-next-line max-len
// @ts-expect-error Could not find a declaration file for module '@virgodev/chat/mixins/ChatAppMixin'.
import ChatAppMixin from '@virgodev/chat/mixins/ChatAppMixin';
import {
  CalendarIcon,
  DollarSignIcon,
  HexagonIcon,
  MailIcon,
  MenuIcon,
  SearchIcon,
  UserIcon,
  UserPlusIcon,
  UsersIcon,
  XIcon,
} from '@zhuowenli/vue-feather-icons';
import Chat from '@/components/chat/Chat.vue';
import ChatConversation from '@/components/chat/ChatConversation.vue';
import Notifications from '@/components/Notifications.vue';
import Search from '@/components/Search.vue';
import TransitionSlide from '@/components/TransitionSlide.vue';
import UserMenu from '@/components/UserMenu.vue';

export default defineComponent({
  components: {
    CalendarIcon,
    DollarSignIcon,
    HexagonIcon,
    MailIcon,
    MenuIcon,
    SearchIcon,
    UserIcon,
    UserPlusIcon,
    UsersIcon,
    Chat,
    ChatConversation,
    Notifications,
    Search,
    TransitionSlide,
    UserMenu,
    XIcon,
  },
  mixins: [
    ChatAppMixin,
  ],
  data() {
    let keyboardType;

    // We will check to see if this device's primary input mechanism can
    // conveniently hover over elements and use that to decide if this device's
    // keyboard is physical or virtual. This is not always going to be
    // accurate, but it should be accurate most of the time.

    if (window.matchMedia('(hover: hover)').matches) {
      keyboardType = 'physical';
    } else {
      keyboardType = 'virtual';
    }

    return {
      appStatus: 'launching' as 'launching' | 'launched' | 'error',
      headerSearchShowingOnNarrowScreens: false,
      sidebarShowingOnNarrowScreens: false,
      sidebarTransitionDuration: 0,
      viewportWidthAtLeast1024: false,
      openMenu: '' as '' | 'search_results' | 'chat' | 'notifications' | 'user',
      activeModals: [] as Array<HTMLDialogElement>,
      keyboardType,
      activeChatRoomName: '',
      wsServer: `${process.env.VUE_APP_API_URL}../ws/chat/`,
      wsToken: null as string | null,
    };
  },
  watch: {
    $route() {
      this.openMenu = '';

      if (this.loggedIn && this.userData.signup_data !== undefined) {
        const currentRoute = this.$router.currentRoute.value.name as string;

        if (![
          '404',
          'document',
          'logout',
          'onboard',
        ].includes(currentRoute)) {
          this.$router.push({ name: 'onboard' });
        }
      }
    },
    loggedIn() {
      this.setHtmlClass();

      if (this.loggedIn) {
        this.wsToken = this.$store.state.drfToken;
      } else {
        this.wsToken = null;
      }
    },
  },
  created() {
    this.launchApp();

    const mql = window.matchMedia('(min-width: 1024px)');

    if (mql.matches) {
      this.viewportWidthAtLeast1024 = true;
    }

    mql.addEventListener('change', (e) => {
      this.viewportWidthAtLeast1024 = e.matches;
    });

    document.documentElement.addEventListener('keydown', (e) => {
      if (e.key === 'Escape' && this.activeModals.length) {
        e.preventDefault();
        const activeModal = this.activeModals.pop() as HTMLDialogElement;
        const activeModalCloseButton = activeModal.querySelector('.js-modal-close') as HTMLButtonElement;
        activeModalCloseButton.click();
      }
    });

    setInterval(() => {
      this.$store.commit('timestampKeySuffixIncrement');
    }, 60000);
  },
  methods: {
    chatButtonClicked() {
      if (this.openMenu === 'chat') {
        this.openMenu = '';
      } else {
        this.openMenu = 'chat';
      }
    },
    closeMenusIfNecessary(e: PointerEvent) {
      if (e.target) {
        const target = e.target as HTMLElement;

        if (target.closest(
          '#search-component, '
          + '#chat-component, '
          + '#notifications-component, '
          + '#user-menu-component',
        ) === null) {
          this.openMenu = '';
        }
      }
    },
    headerMenuButtonClicked() {
      this.sidebarTransitionDuration = 200;
      this.sidebarShowingOnNarrowScreens = !this.sidebarShowingOnNarrowScreens;
    },
    headerSearchShowButtonClicked() {
      this.headerSearchShowingOnNarrowScreens = true;

      this.$nextTick(() => {
        const headerSearchInput = document.getElementById('search-input') as HTMLInputElement;
        headerSearchInput.focus();
      });
    },
    async launchApp() {
      this.appStatus = 'launching';

      if (this.$store.state.drfToken === null) {
        this.setHtmlClass();
        this.appStatus = 'launched';
      } else {
        const responseData = await this.api({
          url: 'users/my_data/',
        });

        if (responseData.status === 200) {
          this.$store.commit('userData', responseData.body);
          this.$store.commit('loggedIn', true);
          this.appStatus = 'launched';
        } else {
          this.appStatus = 'error';
        }
      }
    },
    notificationsButtonClicked() {
      if (this.openMenu === 'notifications') {
        this.openMenu = '';
      } else {
        this.openMenu = 'notifications';
      }
    },
    async openChatRoom(chatRoomName: string) {
      this.openMenu = '';

      if (this.activeChatRoomName === chatRoomName) {
        return;
      }

      let proceed;

      if (this.activeChatRoomName === '') {
        proceed = true;
      } else {
        proceed = await (this.$refs.chatConversation as any).closeChat();
      }

      if (proceed) {
        this.activeChatRoomName = chatRoomName;
      }
    },
    setHtmlClass() {
      let classToAdd;
      let classToRemove;

      if (this.loggedIn && this.userData.signup_data === undefined) {
        classToAdd = 'is-authenticated';
        classToRemove = 'is-anonymous-or-not-onboarded';
      } else {
        classToAdd = 'is-anonymous-or-not-onboarded';
        classToRemove = 'is-authenticated';
      }

      document.documentElement.classList.remove(classToRemove);
      document.documentElement.classList.add(classToAdd);
    },
    userButtonClicked() {
      if (this.openMenu === 'user') {
        this.openMenu = '';
      } else {
        this.openMenu = 'user';
      }
    },
  },
});
