<template>
  <div
    id="search-component"
    class="component"
  >
    <div class="input-with-prefix">
      <span class="input-prefix">
        <search-icon />
      </span>
      <input
        id="search-input"
        v-model="searchKeywords"
        type="text"
        placeholder="Search"
        @focus="searchFieldFocus"
        @input="searchFieldInput"
      >
    </div>

    <div
      v-show="showSearchResults"
      class="search-results-container"
    >
      <div class="search-results">
        <div class="search-types">
          <button
            type="button"
            class="btn-transparent"
            :class="{ 'active': searchType === 'users' }"
            aria-label="Show users matching your search"
            :aria-pressed="searchType === 'users'"
            @click="searchType = 'users'"
          >
            <user-icon />
            Users
          </button>

          <button
            type="button"
            class="btn-transparent"
            :class="{ 'active': searchType === 'teams' }"
            aria-label="Show teams matching your search"
            :aria-pressed="searchType === 'teams'"
            @click="searchType = 'teams'"
          >
            <mdicon
              name="account-group-outline"
              size="16"
            />
            Teams
          </button>

          <button
            type="button"
            class="btn-transparent"
            :class="{ 'active': searchType === 'games' }"
            aria-label="Show games matching your search"
            :aria-pressed="searchType === 'games'"
            @click="searchType = 'games'"
          >
            <mdicon
              name="gamepad-variant-outline"
              size="16"
            />
            Games
          </button>
        </div>

        <template v-if="searchResultsStatus === 'loaded'">
          <div
            v-if="searchResults.results.length"
            class="subtle-scrollbar search-results-list-and-load-more-content"
          >
            <ul
              :class="`search-results-list search-results-list-${searchType}`"
              aria-label="Search results"
            >
              <template v-if="searchType === 'users'">
                <li
                  v-for="result of getUserSearchResults()"
                  :key="`result${result.username}`"
                >
                  <router-link
                    :to="{ name: 'user_profile', params: { username: result.username } }"
                  >
                    <profile-image :user="result" />

                    <div>
                      <div>{{ result.first_name }} {{ result.last_name }}</div>

                      <ul v-if="result.search_matches.length">
                        <li
                          v-for="(match, index) of result.search_matches"
                          :key="`match${index}`"
                          class="match"
                        >
                          <strong>{{ match[0] }}</strong>:
                          <span v-html="match[1]" />
                        </li>
                      </ul>
                    </div>
                  </router-link>
                </li>
              </template>

              <template v-else-if="searchType === 'teams'">
                <li
                  v-for="result of getTeamSearchResults()"
                  :key="`result${result.slug}`"
                >
                  <router-link :to="{ name: 'team', params: { teamSlug: result.slug } }">
                    <p class="team-name">
                      {{ result.name }}
                    </p>

                    <p class="team-public-or-private">
                      <template v-if="result.visibility === 'public'">
                        Public
                      </template>

                      <template v-else>
                        Private
                      </template>

                      Team
                    </p>

                    <p class="team-game-name-and-year">
                      Plays {{ result.game_name_and_year }}
                    </p>

                    <img
                      v-if="result.cover_image"
                      :src="result.cover_image"
                      alt
                      class="img-fluid team-cover-image"
                    >
                  </router-link>
                </li>
              </template>

              <template v-else-if="searchType === 'games'">
                <li
                  v-for="result of getGameSearchResults()"
                  :key="`result${result.slug}`"
                >
                  <router-link :to="{ name: 'game', params: { gameSlug: result.slug } }">
                    <img
                      v-if="result.cover_image_id"
                      :src="`https://images.igdb.com/igdb/image/upload/t_thumb/${result.cover_image_id}.jpg`"
                      alt
                    >

                    <div>
                      {{ result.name }}
                      ({{ result.first_release_date.substring(0, 4) }})
                    </div>
                  </router-link>
                </li>
              </template>
            </ul>

            <div
              v-show="searchResults.next"
              class="horizontal-padding load-more-content"
            >
              <button
                v-show="moreSearchResultsStatus === 'idle' && searchResults.next"
                ref="loadMoreSearchResultsButton"
                type="button"
                class="btn btn-outline-primary load-more-search-results-button"
                @click="loadMoreSearchResults(true)"
              >
                Load More Search Results
              </button>

              <spinner
                v-if="moreSearchResultsStatus === 'loading'"
                preset="large"
              />

              <template v-else-if="moreSearchResultsStatus === 'error'">
                <alert variant="danger">
                  An error occurred while trying to load more search results.
                  Please check your connection and try again.
                </alert>

                <button
                  type="button"
                  class="btn btn-outline-primary"
                  @click="loadMoreSearchResults(true)"
                >
                  Try Again
                </button>
              </template>
            </div>
          </div>

          <div
            v-else
            class="no-results text-center"
          >
            No results
          </div>
        </template>

        <div
          v-else
          class="horizontal-padding loading-or-error"
        >
          <spinner
            v-if="['idle', 'loading'].includes(searchResultsStatus)"
            preset="large"
          />

          <template v-else-if="searchResultsStatus === 'error'">
            <alert variant="danger">
              An error occurred while trying to load the search results. Please
              check your connection and try again.
            </alert>

            <button
              type="button"
              class="btn btn-outline-primary"
              @click="loadSearchResults(true)"
            >
              Try Again
            </button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ApiError } from '@virgodev/bazaar/functions/api';
import debounce from 'debounce';
import { defineComponent } from 'vue';
import { SearchIcon, UserIcon } from '@zhuowenli/vue-feather-icons';
import ProfileImage from '@/components/users/ProfileImage.vue';
import { GameInterface } from '@/interfaces/games';
import { TeamInterface } from '@/interfaces/teams';
import { UserDirectoryResultInterface } from '@/interfaces/users';

export default defineComponent({
  components: {
    SearchIcon,
    UserIcon,
    ProfileImage,
  },
  data: () => ({
    abortController: null as null | AbortController,
    searchKeywords: '',
    searchType: 'users' as 'users' | 'teams' | 'games',
    searchResultsStatus: 'idle' as 'idle' | 'loading' | 'loaded' | 'error',
    searchResults: {} as {
      count: number,
      next: string | null,
      previous: string | null,
      results: Array<UserDirectoryResultInterface | TeamInterface | GameInterface>,
    },
    moreSearchResultsStatus: 'idle' as 'idle' | 'loading' | 'loaded' | 'error',
    observer: null as null | IntersectionObserver,
  }),
  computed: {
    showSearchResults() {
      return window.app.openMenu === 'search_results';
    },
  },
  watch: {
    searchType() {
      this.loadSearchResults(false);
    },
  },
  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    getGameSearchResults() {
      return this.searchResults.results as Array<GameInterface>;
    },
    getTeamSearchResults() {
      return this.searchResults.results as Array<TeamInterface>;
    },
    getUserSearchResults() {
      return this.searchResults.results as Array<UserDirectoryResultInterface>;
    },
    async loadSearchResults(includeDelay: boolean) {
      if (includeDelay) {
        // This delay is here for situations in which a click would undesirably
        // cause the search results to close.
        await new Promise((resolve) => setTimeout(resolve, 0));
      }

      if (this.abortController) {
        this.abortController.abort();
      }

      const searchKeywords = this.searchKeywords.trim();

      if (searchKeywords === '') {
        window.app.openMenu = '';
        this.searchResultsStatus = 'idle';
        this.searchResults = {
          count: 0,
          next: null,
          previous: null,
          results: [],
        };
        return;
      }

      window.app.openMenu = 'search_results';

      this.searchResultsStatus = 'loading';
      this.abortController = new AbortController();

      const responseData = await this.api({
        url: `${this.searchType}/search/?q=${searchKeywords}`,
        options: {
          signal: this.abortController.signal,
        },
      });

      if (responseData.status === 200) {
        this.searchResults = responseData.body;
        this.searchResultsStatus = 'loaded';

        if (this.searchResults.next) {
          this.$nextTick(() => {
            this.observer = new IntersectionObserver((entries) => {
              entries.forEach((entry) => {
                if (entry.intersectionRatio) {
                  this.loadMoreSearchResults(false);
                }
              });
            });

            this.observer.observe(this.$refs.loadMoreSearchResultsButton as HTMLButtonElement);
          });
        }
      } else if (
        !(
          Object.prototype.hasOwnProperty.call(responseData, 'error')
          && (responseData as ApiError).error.name === 'AbortError'
        )
      ) {
        this.searchResultsStatus = 'error';
      }
    },
    async loadMoreSearchResults(includeDelay: boolean) {
      if (includeDelay) {
        // This delay is here for situations in which a click would undesirably
        // cause the search results to close.
        await new Promise((resolve) => setTimeout(resolve, 0));
      }

      if (this.abortController) {
        this.abortController.abort();
      }

      this.moreSearchResultsStatus = 'loading';
      this.abortController = new AbortController();

      const responseData = await this.api({
        url: this.searchResults.next as string,
        options: {
          signal: this.abortController.signal,
        },
      });

      if (responseData.status === 200) {
        this.searchResults.count = responseData.body.count;
        this.searchResults.next = responseData.body.next;
        this.searchResults.previous = responseData.body.previous;
        this.searchResults.results = this.searchResults.results.concat(
          responseData.body.results,
        );
        this.moreSearchResultsStatus = 'idle';
      } else if (
        !(
          Object.prototype.hasOwnProperty.call(responseData, 'error')
          && (responseData as ApiError).error.name === 'AbortError'
        )
      ) {
        this.moreSearchResultsStatus = 'error';
      }
    },
    searchFieldFocus() {
      if (this.searchResultsStatus !== 'idle') {
        window.app.openMenu = 'search_results';
      }
    },
    // eslint-disable-next-line func-names
    searchFieldInput: debounce(function () {
      // @ts-expect-error 'this' implicitly has type 'any' because it does not
      // have a type annotation.
      this.loadSearchResults(false);
    }, 300),
  },
});
</script>

<style lang="scss" scoped>
  .search-results-container {
    position: relative;
  }

  .search-results {
    position: absolute;
    width: 100%;
    background-color: var(--gray-darker);
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    box-shadow: 0 3px 30px #000;
    overflow: hidden;
  }

  .search-types {
    display: flex;
    justify-content: space-between;

    button {
      padding: 6px 3px;
      width: 100%;
      background-color: var(--gray-dark);
      border-bottom: 1px solid var(--gray);
      transition: background-color 0.15s ease-in-out;

      &.active {
        background-color: var(--blue);
      }

      &:not(.active):hover {
        background-color: var(--gray);
      }

      &:focus {
        outline: 0;
        border-bottom-color: #fff;
      }

      @supports #{'\selector(*:focus-visible)'}  {
        &:focus {
          border-bottom-color: var(--gray);
        }

        &:focus-visible {
          border-bottom-color: #fff;
        }
      }

      &[aria-pressed="true"] {
        cursor: default;
      }

      + button {
        border-left: 1px solid var(--gray);
      }
    }
  }

  .search-results-list-and-load-more-content {
    max-height: 40vh;
    overflow-y: auto;
    overscroll-behavior-y: contain;
  }

  .search-results-list {
    margin: 0;
    padding-left: 0;
    list-style: none;

    > li + li a {
      border-top: 1px solid var(--gray-darkest);
    }

    a {
      display: block;
      padding: 10px 16px;
      color: #fff;
      transition: background-color 0.15s ease-in-out;
      word-wrap: break-word;

      @supports #{'\selector(*:focus-visible)'}  {
        &:focus {
          outline: 0;
        }

        &:focus-visible {
          background-color: var(--gray-dark);
        }
      }

      &:hover {
        background-color: var(--gray-dark);
        text-decoration: none;
      }
    }
  }

  .search-results-list-users a {
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: auto minmax(0, 1fr);
  }

  .match :deep(b) {
    background-color: var(--green);
  }

  .team-name,
  .team-public-or-private,
  .team-game-name-and-year {
    margin-bottom: 0;
    text-align: center;
  }

  .team-name {
    font-weight: bold;
  }

  .team-cover-image {
    display: block;
    margin-top: 1rem;
  }

  .search-results-list-games a {
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: auto minmax(0, 1fr);
    align-items: center;

    img {
      width: 50px;
    }
  }

  .load-more-content {
    margin: 1rem 0;
  }

  .load-more-search-results-button {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  .no-results,
  .loading-or-error {
    margin: 1rem 0;
  }
</style>
