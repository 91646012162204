
import { defineComponent } from 'vue';
import { LogOutIcon, UserIcon, SettingsIcon } from '@zhuowenli/vue-feather-icons';
import ProfileImage from '@/components/users/ProfileImage.vue';

export default defineComponent({
  components: {
    LogOutIcon,
    UserIcon,
    SettingsIcon,
    ProfileImage,
  },
  props: {
    buttonTabindex: {
      type: Number,
      default: 0,
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'buttonClicked',
  ],
});
