
import { ApiError } from '@virgodev/bazaar/functions/api';
import { SweetAlertResult } from 'sweetalert2';
import { v1 as uuidv1 } from 'uuid';
import { defineComponent, nextTick } from 'vue';
import { mapState } from 'vuex';
import { PaperclipIcon, SendIcon, XIcon } from '@zhuowenli/vue-feather-icons';
import escapeHtml from 'escape-html';
import linkify from 'linkifyjs/html';
import getChatRoomMemberNames from '@/methods/get_chat_room_member_names';
// @ts-expect-error Could not find a declaration file for module '@virgodev/chat/mixins/ChatMixin'.
import ChatMixin from '@virgodev/chat/mixins/ChatMixin';
import AttachmentUpload from '@/components/AttachmentUpload.vue';
import ProfileImage from '@/components/users/ProfileImage.vue';
import { MessageAlternateInterface, MessageInterface } from '@/interfaces/chat';
import { NewAttachmentInterface } from '@/interfaces/posts';
import { UserWithChatRoomIdInterface } from '@/interfaces/users';

export default defineComponent({
  components: {
    PaperclipIcon,
    SendIcon,
    XIcon,
    AttachmentUpload,
    ProfileImage,
  },
  mixins: [
    ChatMixin,
  ],
  emits: [
    'chatClosed',
  ],
  data: () => ({
    chatRoom: window.app.activeChatRoomName,
    abortController: null as null | AbortController,
    initialLoadingComplete: false,
    otherMembers: [] as Array<UserWithChatRoomIdInterface>,
    otherMembersMap: {} as Record<number, UserWithChatRoomIdInterface>,
    messages: [] as Array<MessageInterface>,
    pastMessages: {
      status: 'loading' as 'idle' | 'loading' | 'error',
      next: null as string | null,
    },
    pastMessagesObserver: null as null | IntersectionObserver,
    pageSize: 10,
    scrollHeightBeforeAddingToPastMessages: 0,
    newMessageBody: '',
    newMessageAttachment: null as null | NewAttachmentInterface,
    fileInputKeySuffix: 0,
    newMessageFormSubmitting: false,
  }),
  computed: {
    ...mapState([
      'timestampKeySuffix',
    ]),
    chatWsSocketStatus(): null | 'connecting' | 'connected' | 'reconnecting' | 'closed_cleanly' | 'closed' {
      return (this.$root as any).wsSocketStatus;
    },
    chatWsSocketReconnectData(): null | {
      attempt: number;
      countdown: number;
      intervalID: null | number;
      } {
      return (this.$root as any).wsSocketReconnectData;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$root.wsSocketStatus': function (newValue) {
      if (newValue === 'connected' && this.initialLoadingComplete) {
        // Messages may have been missed

        if (this.abortController) {
          this.abortController.abort();
        }

        if (this.pastMessagesObserver) {
          this.pastMessagesObserver.unobserve(this.$refs.belowSpinner as HTMLDivElement);
        }

        this.initialLoadingComplete = false;
        this.messages = [];
        this.loadPastMessages();
      }
    },
  },
  async created() {
    this.loadPastMessages();
  },
  beforeUnmount() {
    if (this.pastMessagesObserver) {
      this.pastMessagesObserver.unobserve(this.$refs.belowSpinner as HTMLDivElement);
    }
  },
  methods: {
    getChatRoomMemberNames,
    beforeChatMessage(message: MessageAlternateInterface) {
      // We do not want the message to say that it was posted in the future
      // (i.e. "in less than a minute")

      // eslint-disable-next-line no-param-reassign
      message.timestamp = new Date(Date.now() - 2000).toISOString();
    },
    afterChatMessage() {
      this.scrollToBottom();
    },
    attachFile(e: Event) {
      this.fileInputKeySuffix += 1;
      const fileInput = e.target as HTMLInputElement;
      const file = (fileInput.files as FileList)[0];

      if (file) {
        if (file.size <= process.env.VUE_APP_MAX_ATTACHMENT_BYTES) {
          const reader = new FileReader();

          reader.onload = () => {
            this.newMessageAttachment = {
              key: Date.now(),
              status: 'Idle',
              file,
            };
          };

          reader.readAsDataURL(file);
        } else {
          this.$swal('Unable to Upload File', 'That file is too large to upload.');
        }
      }
    },
    closeChat() {
      if (this.getFormIsBlank()) {
        this.$emit('chatClosed');
        return true;
      }

      return this.$swal.fire({
        title: 'Discard Message?',
        text: 'Do you really want to discard your chat message?',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-light',
        },
        showCancelButton: true,
        confirmButtonText: 'Yes, Discard It',
      }).then(async (result: SweetAlertResult) => {
        if (result.isConfirmed) {
          this.$emit('chatClosed');
          return true;
        }

        return false;
      });
    },
    handlePastMessagesIntersect(entries: Array<IntersectionObserverEntry>) {
      entries.forEach((entry) => {
        if (entry.isIntersecting && this.pastMessages.status === 'idle') {
          this.loadPastMessages();
        }
      });
    },
    linkify(value: string) {
      return linkify(
        escapeHtml(value),
        {
          defaultProtocol: 'https',
        },
      );
    },
    async loadPastMessages() {
      this.pastMessages.status = 'loading';
      this.abortController = new AbortController();

      let url;

      if (this.initialLoadingComplete) {
        url = this.pastMessages.next as string;
      } else {
        url = `${process.env.VUE_APP_API_URL}chat/history/?chat_room_name=${this.chatRoom}`;
      }

      const responseData = await this.api({
        url,
        options: {
          signal: this.abortController.signal,
        },
      });

      if (responseData.status === 200) {
        const chatContent = this.$refs.chatContent as HTMLDivElement;

        if (this.initialLoadingComplete) {
          this.scrollHeightBeforeAddingToPastMessages = chatContent.scrollHeight;
        } else {
          this.otherMembers = responseData.body.other_members;

          const otherMembersMap = {} as Record<number, UserWithChatRoomIdInterface>;

          this.otherMembers.forEach((otherMember) => {
            otherMembersMap[otherMember.chat_room_id] = otherMember;
          });

          this.otherMembersMap = otherMembersMap;

          // @ts-expect-error joinChatRoom is from ChatMixin.js
          this.joinChatRoom(this.chatRoom);
        }

        if (responseData.body.results.length) {
          responseData.body.results.reverse();

          if (!this.initialLoadingComplete) {
            // Reset this.messages in case any messages came in before
            // the initial loading

            this.messages = [];
          }

          this.messages = responseData.body.results.concat(this.messages);
        }

        this.pastMessages = {
          status: 'idle',
          next: responseData.body.next,
        };

        if (this.initialLoadingComplete) {
          if (CSS.supports('background: -webkit-named-image(i)')) {
            // Safari only

            nextTick(() => {
              const scrollHeightAfterAddingToPastMessages = chatContent.scrollHeight;
              chatContent.scrollTop = chatContent.scrollTop
                + scrollHeightAfterAddingToPastMessages
                - this.scrollHeightBeforeAddingToPastMessages;
            });
          }

          if (this.pastMessages.next === null && this.pastMessagesObserver) {
            this.pastMessagesObserver.unobserve(this.$refs.belowSpinner as HTMLDivElement);
          }
        } else {
          this.initialLoadingComplete = true;

          nextTick(() => {
            this.scrollToBottom();

            if (this.pastMessages.next) {
              this.pastMessagesObserver = new IntersectionObserver(
                this.handlePastMessagesIntersect,
              );

              this.pastMessagesObserver.observe(this.$refs.belowSpinner as HTMLDivElement);
            }
          });
        }
      } else if (
        !(
          Object.prototype.hasOwnProperty.call(responseData, 'error')
          && (responseData as ApiError).error.name === 'AbortError'
        )
      ) {
        this.pastMessages.status = 'error';
      }
    },
    resetNewMessageForm() {
      this.newMessageBody = '';
      this.newMessageAttachment = null;
    },
    safeConnectToChatWebsocketServer() {
      (this.$root as any).safeConnectToWebsocketServer();
    },
    scrollToBottom() {
      const chatContent = this.$refs.chatContent as HTMLDivElement;

      if (chatContent) {
        chatContent.scroll(0, chatContent.scrollHeight);
      }
    },
    secondsToHumanReadable(seconds: number) {
      // eslint-disable-next-line no-param-reassign
      seconds = Math.ceil(seconds);

      if (seconds) {
        const h = Math.floor(seconds / 3600);
        const m = Math.floor((seconds % 3600) / 60);
        const s = Math.floor(seconds % 3600 % 60);

        const hDisplay = h > 0 ? h + (h === 1 ? ' hr, ' : ' hrs, ') : '';
        const mDisplay = m > 0 ? m + (m === 1 ? ' min, ' : ' mins, ') : '';
        const sDisplay = s > 0 ? s + (s === 1 ? ' sec' : ' secs') : '';

        return (hDisplay + mDisplay + sDisplay).replace(/, $/, '');
      }

      return '0 secs';
    },
    getFormIsBlank() {
      return this.newMessageBody.trim() === '' && this.newMessageAttachment === null;
    },
    async submitNewMessageForm() {
      if (this.getFormIsBlank()) {
        this.$swal('Message or File Required', 'Please write a message and/or attach a file.');
        return;
      }

      if (this.newMessageAttachment && this.newMessageAttachment.status !== 'Uploaded') {
        let text;

        if (['Idle', 'Uploading'].includes(this.newMessageAttachment.status)) {
          text = 'The file is still uploading.';
        } else {
          text = 'The file failed to upload. Please either remove it or try uploading it again.';
        }

        this.$swal('Cannot Send Message', text);
        return;
      }

      this.newMessageFormSubmitting = true;

      const data = {
        room: this.chatRoom,
        body: this.newMessageBody,
        enc_nonce: uuidv1(),
      } as Record<string, unknown>;

      if (this.newMessageAttachment) {
        data.attachment_id = this.newMessageAttachment.id;
      }

      const responseData = await this.api({
        url: 'chat/history/',
        method: 'POST',
        json: data,
      });

      this.newMessageFormSubmitting = false;

      if (responseData.status === 201) {
        this.resetNewMessageForm();
      } else {
        let text;

        if (responseData.status === 400) {
          text = responseData.body;
        } else {
          text = 'Unable to communicate with the server. Please check your '
          + 'connection and try again.';
        }

        this.$swal('Failed to Send Message', text);
      }
    },
    textareaKeydown(e: KeyboardEvent) {
      if (e.key === 'Enter' && window.app.keyboardType === 'physical') {
        e.preventDefault();
        this.submitNewMessageForm();
      }
    },
    triggerFileInputClick() {
      (this.$refs.fileInput as HTMLInputElement).click();
    },
  },
});
