<template>
  <div
    :class="{ bordered: bordered }"
    :style="bordered ? `padding: ${size * 0.0625}px;` : ''"
  >
    <img
      v-if="profileImageUrl"
      :src="profileImageUrl"
      :alt="fullName"
      :style="styleAttr"
    >

    <span
      v-else
      :aria-label="fullName"
      :style="styleAttr"
    >
      {{ initials }}
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { UserInterface } from '@/interfaces/users';

export default defineComponent({
  props: {
    user: {
      type: Object as PropType<UserInterface>,
      required: false,
      default: undefined,
    },
    size: {
      type: Number,
      required: false,
      default: 48,
    },
    bordered: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    fullName: '',
    initials: '',
    profileImageUrl: '',
  }),
  computed: {
    styleAttr(): string {
      let styleAttr = `width: ${this.size}px;`
        + `height: ${this.size}px;`;

      if (!this.profileImageUrl) {
        styleAttr += `font-size: ${this.size / 2}px;`
          + 'color: #000;';

        const colors = [
          'var(--red)',
          'var(--yellow)',
          'var(--green)',
          'var(--blue)',
          'var(--purple)',
          'var(--gray-light)',
        ];

        let value = 0;

        let i = this.initials.length;
        // eslint-disable-next-line no-plusplus
        while (i--) {
          value += this.initials.charCodeAt(i) - 64;
        }

        let index = Math.floor(value * 0.2);

        if (index > 5) {
          index = 5;
        }

        styleAttr += `background-color: ${colors[index]};`;
      }

      return styleAttr;
    },
  },
  created() {
    let user;

    if (this.user === undefined) {
      user = this.userData;
    } else {
      user = JSON.parse(JSON.stringify(this.user));
    }

    this.fullName = `${user.first_name} ${user.last_name}`;

    if (user.profile_image) {
      this.profileImageUrl = user.profile_image;
    } else {
      this.initials = user.first_name.slice(0, 1).toUpperCase()
        + user.last_name.slice(0, 1).toUpperCase();
    }
  },
});
</script>

<style lang="scss" scoped>
  .bordered {
    position: relative;
    display: inline-block;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: var(--blue);
      clip-path: polygon(
        50% 0,
        7% 25%,
        7% 75%,
        50% 100%,
        50% 98%,
        9% 74%,
        9% 26%,
        50% 2%,
        91% 26%,
        91% 74%,
        50% 98%,
        50% 100%,
        93% 75%,
        93% 25%
      );
    }
  }

  img,
  span {
    vertical-align: middle;
    user-select: none;
    clip-path: polygon(50% 0, 92.5% 25%, 92.5% 75%, 50% 100%, 7.5% 75%, 7.5% 25%);
  }

  span {
    display: inline-grid;
    justify-content: center;
    align-content: center;
    flex-shrink: 0;
  }
</style>
