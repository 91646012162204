// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/ban-types
export default function goBackOrToRoute(this: any, route: string | object = 'feed'): void {
  if (this.$store.state.latestFromRoute.matched.length) {
    this.$router.go(-1);
  } else {
    if (typeof route === 'string') {
      // eslint-disable-next-line no-param-reassign
      route = { name: route };
    }

    this.$router.push(route);
  }
}
