<template>
  <div
    class="component"
    @keydown.esc="closeChat"
  >
    <div class="chat-header">
      <template v-if="initialLoadingComplete">
        <profile-image
          :user="otherMembers[0]"
          :size="34"
          class="profile-image"
        />

        <div class="member-names">
          {{ getChatRoomMemberNames(otherMembers) }}
        </div>
      </template>

      <template v-else>
        Chat
      </template>

      <button
        type="button"
        class="btn-transparent"
        aria-label="Close this chat"
        @click="closeChat"
      >
        <x-icon />
      </button>
    </div>

    <div class="below-chat-header">
      <div
        v-if="
          chatWsSocketStatus !== null
            && ['closed', 'reconnecting'].includes(chatWsSocketStatus)
        "
        class="socket-message"
      >
        <template v-if="chatWsSocketStatus === 'closed' && chatWsSocketReconnectData !== null">
          You are not connected to the chat server right now. Connecting in
          {{ secondsToHumanReadable(chatWsSocketReconnectData.countdown) }}...

          <button
            type="button"
            class="btn-transparent"
            @click="safeConnectToChatWebsocketServer"
          >
            Try now
          </button>
        </template>

        <template v-else>
          Reconnecting to the chat server...
        </template>
      </div>

      <div
        ref="chatContent"
        class="chat-content"
      >
        <div v-if="!initialLoadingComplete || pastMessages.next || messages.length > pageSize">
          <spinner
            v-if="pastMessages.status === 'loading'"
            preset="large"
          />

          <alert v-else-if="initialLoadingComplete && pastMessages.next === null">
            There are no more messages.
          </alert>
        </div>

        <div ref="belowSpinner" />

        <template v-if="pastMessages.status === 'error'">
          <alert variant="danger">
            An error occurred while trying to load

            <template v-if="initialLoadingComplete">
              your past messages.
            </template>

            <template v-else>
              this chat.
            </template>

            Please check your connection and try again.
          </alert>

          <button
            type="button"
            class="btn btn-outline-primary"
            @click="loadPastMessages"
          >
            Try Again
          </button>
        </template>

        <div
          v-if="initialLoadingComplete && messages.length"
          class="messages"
        >
          <div
            v-for="message of messages"
            :key="`message${message.id}`"
            :class="'message ' + (
              message.sender_id === userData.id ? 'my-message' : 'other-message'
            )"
          >
            <profile-image
              v-if="message.sender_id !== userData.id"
              :user="otherMembersMap[message.sender_id]"
              class="profile-image"
            />

            <div
              v-if="message.msg_type === 'text'"
              class="message-body"
              v-html="linkify(message.message)"
            />

            <div
              v-else-if="['image', 'video'].includes(message.msg_type)"
              class="message-body"
            >
              <img
                v-if="message.msg_type === 'image'"
                :src="message.upload"
                alt
                class="img-fluid"
              >

              <video
                v-else
                :src="`${message.upload}#t=0.1`"
                controls
                playsinline
              />
            </div>

            <div
              v-if="message.timestamp"
              :key="`timestamp${timestampKeySuffix}`"
              class="message-timestamp"
            >
              {{ timestampDistanceDisplay(message.timestamp) }}
            </div>
          </div>
        </div>

        <alert v-else-if="initialLoadingComplete">
          You don't have any chats with
          {{ getChatRoomMemberNames(otherMembers) }} yet.
        </alert>
      </div>

      <form
        v-if="initialLoadingComplete"
        ref="newMessageForm"
        class="new-message-form"
        :class="{ 'has-attachment': newMessageAttachment }"
        @submit.prevent="submitNewMessageForm"
      >
        <textarea
          ref="newMessageBody"
          v-model="newMessageBody"
          placeholder="Write a message"
          @keydown="textareaKeydown"
        />

        <div class="buttons">
          <button
            v-tooltip="'Attach a file'"
            aria-label="Attach a file"
            type="button"
            class="btn btn-outline-light btn-sm"
            :disabled="newMessageAttachment !== null"
            @click="triggerFileInputClick"
          >
            <paperclip-icon />
          </button>

          <button
            v-tooltip="'Send message'"
            aria-label="Send message"
            type="submit"
            class="btn btn-primary btn-sm"
            :disabled="newMessageFormSubmitting"
          >
            <spinner v-if="newMessageFormSubmitting" />

            <send-icon v-else />
          </button>
        </div>

        <input
          ref="fileInput"
          :key="`file${fileInputKeySuffix}`"
          type="file"
          accept="image/gif, image/jpeg, image/png, video/mp4, video/quicktime, video/x-m4v"
          tabindex="-1"
          aria-hidden="true"
          @change="attachFile"
        >

        <attachment-upload
          v-if="newMessageAttachment !== null"
          :attachment="newMessageAttachment"
          :chat-room-name="chatRoom"
          @attachmentRemoved="newMessageAttachment = null"
        />
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import { ApiError } from '@virgodev/bazaar/functions/api';
import { SweetAlertResult } from 'sweetalert2';
import { v1 as uuidv1 } from 'uuid';
import { defineComponent, nextTick } from 'vue';
import { mapState } from 'vuex';
import { PaperclipIcon, SendIcon, XIcon } from '@zhuowenli/vue-feather-icons';
import escapeHtml from 'escape-html';
import linkify from 'linkifyjs/html';
import getChatRoomMemberNames from '@/methods/get_chat_room_member_names';
// @ts-expect-error Could not find a declaration file for module '@virgodev/chat/mixins/ChatMixin'.
import ChatMixin from '@virgodev/chat/mixins/ChatMixin';
import AttachmentUpload from '@/components/AttachmentUpload.vue';
import ProfileImage from '@/components/users/ProfileImage.vue';
import { MessageAlternateInterface, MessageInterface } from '@/interfaces/chat';
import { NewAttachmentInterface } from '@/interfaces/posts';
import { UserWithChatRoomIdInterface } from '@/interfaces/users';

export default defineComponent({
  components: {
    PaperclipIcon,
    SendIcon,
    XIcon,
    AttachmentUpload,
    ProfileImage,
  },
  mixins: [
    ChatMixin,
  ],
  emits: [
    'chatClosed',
  ],
  data: () => ({
    chatRoom: window.app.activeChatRoomName,
    abortController: null as null | AbortController,
    initialLoadingComplete: false,
    otherMembers: [] as Array<UserWithChatRoomIdInterface>,
    otherMembersMap: {} as Record<number, UserWithChatRoomIdInterface>,
    messages: [] as Array<MessageInterface>,
    pastMessages: {
      status: 'loading' as 'idle' | 'loading' | 'error',
      next: null as string | null,
    },
    pastMessagesObserver: null as null | IntersectionObserver,
    pageSize: 10,
    scrollHeightBeforeAddingToPastMessages: 0,
    newMessageBody: '',
    newMessageAttachment: null as null | NewAttachmentInterface,
    fileInputKeySuffix: 0,
    newMessageFormSubmitting: false,
  }),
  computed: {
    ...mapState([
      'timestampKeySuffix',
    ]),
    chatWsSocketStatus(): null | 'connecting' | 'connected' | 'reconnecting' | 'closed_cleanly' | 'closed' {
      return (this.$root as any).wsSocketStatus;
    },
    chatWsSocketReconnectData(): null | {
      attempt: number;
      countdown: number;
      intervalID: null | number;
      } {
      return (this.$root as any).wsSocketReconnectData;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$root.wsSocketStatus': function (newValue) {
      if (newValue === 'connected' && this.initialLoadingComplete) {
        // Messages may have been missed

        if (this.abortController) {
          this.abortController.abort();
        }

        if (this.pastMessagesObserver) {
          this.pastMessagesObserver.unobserve(this.$refs.belowSpinner as HTMLDivElement);
        }

        this.initialLoadingComplete = false;
        this.messages = [];
        this.loadPastMessages();
      }
    },
  },
  async created() {
    this.loadPastMessages();
  },
  beforeUnmount() {
    if (this.pastMessagesObserver) {
      this.pastMessagesObserver.unobserve(this.$refs.belowSpinner as HTMLDivElement);
    }
  },
  methods: {
    getChatRoomMemberNames,
    beforeChatMessage(message: MessageAlternateInterface) {
      // We do not want the message to say that it was posted in the future
      // (i.e. "in less than a minute")

      // eslint-disable-next-line no-param-reassign
      message.timestamp = new Date(Date.now() - 2000).toISOString();
    },
    afterChatMessage() {
      this.scrollToBottom();
    },
    attachFile(e: Event) {
      this.fileInputKeySuffix += 1;
      const fileInput = e.target as HTMLInputElement;
      const file = (fileInput.files as FileList)[0];

      if (file) {
        if (file.size <= process.env.VUE_APP_MAX_ATTACHMENT_BYTES) {
          const reader = new FileReader();

          reader.onload = () => {
            this.newMessageAttachment = {
              key: Date.now(),
              status: 'Idle',
              file,
            };
          };

          reader.readAsDataURL(file);
        } else {
          this.$swal('Unable to Upload File', 'That file is too large to upload.');
        }
      }
    },
    closeChat() {
      if (this.getFormIsBlank()) {
        this.$emit('chatClosed');
        return true;
      }

      return this.$swal.fire({
        title: 'Discard Message?',
        text: 'Do you really want to discard your chat message?',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-light',
        },
        showCancelButton: true,
        confirmButtonText: 'Yes, Discard It',
      }).then(async (result: SweetAlertResult) => {
        if (result.isConfirmed) {
          this.$emit('chatClosed');
          return true;
        }

        return false;
      });
    },
    handlePastMessagesIntersect(entries: Array<IntersectionObserverEntry>) {
      entries.forEach((entry) => {
        if (entry.isIntersecting && this.pastMessages.status === 'idle') {
          this.loadPastMessages();
        }
      });
    },
    linkify(value: string) {
      return linkify(
        escapeHtml(value),
        {
          defaultProtocol: 'https',
        },
      );
    },
    async loadPastMessages() {
      this.pastMessages.status = 'loading';
      this.abortController = new AbortController();

      let url;

      if (this.initialLoadingComplete) {
        url = this.pastMessages.next as string;
      } else {
        url = `${process.env.VUE_APP_API_URL}chat/history/?chat_room_name=${this.chatRoom}`;
      }

      const responseData = await this.api({
        url,
        options: {
          signal: this.abortController.signal,
        },
      });

      if (responseData.status === 200) {
        const chatContent = this.$refs.chatContent as HTMLDivElement;

        if (this.initialLoadingComplete) {
          this.scrollHeightBeforeAddingToPastMessages = chatContent.scrollHeight;
        } else {
          this.otherMembers = responseData.body.other_members;

          const otherMembersMap = {} as Record<number, UserWithChatRoomIdInterface>;

          this.otherMembers.forEach((otherMember) => {
            otherMembersMap[otherMember.chat_room_id] = otherMember;
          });

          this.otherMembersMap = otherMembersMap;

          // @ts-expect-error joinChatRoom is from ChatMixin.js
          this.joinChatRoom(this.chatRoom);
        }

        if (responseData.body.results.length) {
          responseData.body.results.reverse();

          if (!this.initialLoadingComplete) {
            // Reset this.messages in case any messages came in before
            // the initial loading

            this.messages = [];
          }

          this.messages = responseData.body.results.concat(this.messages);
        }

        this.pastMessages = {
          status: 'idle',
          next: responseData.body.next,
        };

        if (this.initialLoadingComplete) {
          if (CSS.supports('background: -webkit-named-image(i)')) {
            // Safari only

            nextTick(() => {
              const scrollHeightAfterAddingToPastMessages = chatContent.scrollHeight;
              chatContent.scrollTop = chatContent.scrollTop
                + scrollHeightAfterAddingToPastMessages
                - this.scrollHeightBeforeAddingToPastMessages;
            });
          }

          if (this.pastMessages.next === null && this.pastMessagesObserver) {
            this.pastMessagesObserver.unobserve(this.$refs.belowSpinner as HTMLDivElement);
          }
        } else {
          this.initialLoadingComplete = true;

          nextTick(() => {
            this.scrollToBottom();

            if (this.pastMessages.next) {
              this.pastMessagesObserver = new IntersectionObserver(
                this.handlePastMessagesIntersect,
              );

              this.pastMessagesObserver.observe(this.$refs.belowSpinner as HTMLDivElement);
            }
          });
        }
      } else if (
        !(
          Object.prototype.hasOwnProperty.call(responseData, 'error')
          && (responseData as ApiError).error.name === 'AbortError'
        )
      ) {
        this.pastMessages.status = 'error';
      }
    },
    resetNewMessageForm() {
      this.newMessageBody = '';
      this.newMessageAttachment = null;
    },
    safeConnectToChatWebsocketServer() {
      (this.$root as any).safeConnectToWebsocketServer();
    },
    scrollToBottom() {
      const chatContent = this.$refs.chatContent as HTMLDivElement;

      if (chatContent) {
        chatContent.scroll(0, chatContent.scrollHeight);
      }
    },
    secondsToHumanReadable(seconds: number) {
      // eslint-disable-next-line no-param-reassign
      seconds = Math.ceil(seconds);

      if (seconds) {
        const h = Math.floor(seconds / 3600);
        const m = Math.floor((seconds % 3600) / 60);
        const s = Math.floor(seconds % 3600 % 60);

        const hDisplay = h > 0 ? h + (h === 1 ? ' hr, ' : ' hrs, ') : '';
        const mDisplay = m > 0 ? m + (m === 1 ? ' min, ' : ' mins, ') : '';
        const sDisplay = s > 0 ? s + (s === 1 ? ' sec' : ' secs') : '';

        return (hDisplay + mDisplay + sDisplay).replace(/, $/, '');
      }

      return '0 secs';
    },
    getFormIsBlank() {
      return this.newMessageBody.trim() === '' && this.newMessageAttachment === null;
    },
    async submitNewMessageForm() {
      if (this.getFormIsBlank()) {
        this.$swal('Message or File Required', 'Please write a message and/or attach a file.');
        return;
      }

      if (this.newMessageAttachment && this.newMessageAttachment.status !== 'Uploaded') {
        let text;

        if (['Idle', 'Uploading'].includes(this.newMessageAttachment.status)) {
          text = 'The file is still uploading.';
        } else {
          text = 'The file failed to upload. Please either remove it or try uploading it again.';
        }

        this.$swal('Cannot Send Message', text);
        return;
      }

      this.newMessageFormSubmitting = true;

      const data = {
        room: this.chatRoom,
        body: this.newMessageBody,
        enc_nonce: uuidv1(),
      } as Record<string, unknown>;

      if (this.newMessageAttachment) {
        data.attachment_id = this.newMessageAttachment.id;
      }

      const responseData = await this.api({
        url: 'chat/history/',
        method: 'POST',
        json: data,
      });

      this.newMessageFormSubmitting = false;

      if (responseData.status === 201) {
        this.resetNewMessageForm();
      } else {
        let text;

        if (responseData.status === 400) {
          text = responseData.body;
        } else {
          text = 'Unable to communicate with the server. Please check your '
          + 'connection and try again.';
        }

        this.$swal('Failed to Send Message', text);
      }
    },
    textareaKeydown(e: KeyboardEvent) {
      if (e.key === 'Enter' && window.app.keyboardType === 'physical') {
        e.preventDefault();
        this.submitNewMessageForm();
      }
    },
    triggerFileInputClick() {
      (this.$refs.fileInput as HTMLInputElement).click();
    },
  },
});
</script>

<style lang="scss" scoped>
  .component {
    position: fixed;
    bottom: 0;
    right: 15px;
    width: 290px;
    background-color: var(--gray-darker);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: 0 0 2px 4px var(--gray-a50);
    z-index: 1;
  }

  .chat-header {
    display: flex;
    gap: 0.25rem;
    align-items: center;
    padding: 5px;
    border-bottom: 1px solid var(--gray);

    button {
      margin-left: auto;
    }
  }

  .member-names {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  .below-chat-header {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 53vh;
  }

  .socket-message {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    padding: 0.5rem;
    background-color: var(--red);
    text-align: center;
    z-index: 1;

    button {
      text-decoration: underline;
    }
  }

  .chat-content {
    flex: 1;
    padding: 5px;
    word-break: break-word;
    overflow-y: auto;
    overscroll-behavior-y: contain;

    .spinner {
      margin-bottom: 1rem !important;
    }

    button {
      margin-bottom: 1rem;
    }
  }

  .messages {
    display: flex;
    flex-direction: column;
  }

  .message {
    margin-bottom: 1rem;
    width: 75%;
  }

  .my-message {
    align-self: flex-end;
  }

  .other-message {
    display: grid;
    grid-column-gap: 0.5rem;
    grid-template-columns: 48px 1fr;

    .message-timestamp {
      grid-column: 2;
    }
  }

  .profile-image {
    align-self: end;
  }

  .message-body {
    padding: 0.5rem;
    border-radius: 0.5rem;
    background-color: var(--gray-light);
    word-break: break-word;

    :deep(a) {
      color: #fff;
      text-decoration: underline;
    }

    .my-message & {
      background-color: var(--blue);
    }
  }

  video {
    width: 100%;
    height: auto;
  }

  .message-timestamp,
  .message-send-status {
    margin-top: 0.25rem;
    font-size: 80%;
    color: var(--gray-light);
  }

  .new-message-form {
    position: relative;
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: 1fr auto;
    padding: 5px;
    border-top: 1px solid var(--gray);
  }

  .buttons {
    display: flex;
    flex-direction: column;

    .btn + .btn {
      margin-top: 0.5rem;
      margin-left: 0;
    }
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 10px;
    opacity: 0;
    pointer-events: none;
    -webkit-tap-highlight-color: transparent;
  }

  .attachment {
    grid-column: 1 / -1;
    margin: 1.5rem auto 0;
    width: 110px;

    :deep(img) {
      width: 100%;
      max-height: 75px;
      object-fit: contain;
    }
  }

  @media (orientation: portrait) {
    .below-chat-header {
      height: 60vh;
    }
  }

  @media (max-height: 515px) {
    .attachment :deep(img) {
      max-height: 50px;
    }
  }

  @media (max-height: 450px) {
    .attachment :deep(img) {
      max-height: 40px;
    }
  }

  @media (max-height: 226px) {
    .below-chat-header {
      height: 32vh;
    }
  }
</style>
