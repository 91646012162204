import api, { addResponseHandler, ResponseHandlerParams } from '@virgodev/bazaar/functions/api';

import capitalizeWords from './capitalize_words';
import dateDisplay from './date_display';
import formatCurrency from './format_currency';
import goBackOrToRoute from './go_back_or_to_route';
import isObjectEmpty from './is_object_empty';
import linebreaksbr from './linebreaksbr';
import logUserOut from './log_user_out';
import pluralize from './pluralize';
import scrollToFirstError from './scroll_to_first_error';
import timestampDisplay from './timestamp_display';
import timestampDistanceDisplay from './timestamp_distance_display';
import toListSentence from './to_list_sentence';
import updateTextareaHeight from './update_textarea_height';

addResponseHandler(401, logUserOut);
addResponseHandler(403, ({ body }: ResponseHandlerParams) => {
  if (body.detail === 'An active subscription is required.') {
    window.app.$router.replace({ name: 'settings_subscription' });
  } else if (body.detail === 'You must be an Independent Professional Gamer.') {
    window.app.$router.replace({ name: 'settings_ipg' });
  } else {
    window.location.href = '/';
  }
});

export default {
  api,
  capitalizeWords,
  dateDisplay,
  formatCurrency,
  goBackOrToRoute,
  isObjectEmpty,
  linebreaksbr,
  logUserOut,
  pluralize,
  scrollToFirstError,
  timestampDisplay,
  timestampDistanceDisplay,
  toListSentence,
  updateTextareaHeight,
};
