import { setGlobalRequestHeader } from '@virgodev/bazaar/functions/api';
import { createStore } from 'vuex';
import { UserDataInterface } from '@/interfaces/users';

const userDataDefault: UserDataInterface = {
  id: 0,
  username: 'john_doe',
  email: 'john_doe@example.com',
  first_name: 'John',
  last_name: 'Doe',
  subscription_status: 'not_subscribed',
  ipg_status: 'not_ipg',
  profile_image: null,
};

export default createStore({
  state: {
    drfToken: JSON.parse(localStorage.getItem('drfToken') || 'null') || null,
    latestFromRoute: { matched: [] },
    payPalIPGRegistrationFeeScriptStatus: 'idle',
    payPalSubscriptionsScriptStatus: 'idle',
    timestampKeySuffix: 0,
    loggedIn: false,
    userData: JSON.parse(JSON.stringify(userDataDefault)) as UserDataInterface,
    userMenuProfileImageKeySuffix: 0,
  },
  mutations: {
    drfToken(state, value) {
      state.drfToken = value;
      localStorage.setItem('drfToken', JSON.stringify(value));

      if (value) {
        setGlobalRequestHeader('Authorization', `Token ${value}`);
      } else {
        setGlobalRequestHeader('Authorization', undefined);
      }
    },
    latestFromRoute(state, value) {
      state.latestFromRoute = value;
    },
    payPalIPGRegistrationFeeScriptStatus(state, value) {
      state.payPalIPGRegistrationFeeScriptStatus = value;
    },
    payPalSubscriptionsScriptStatus(state, value) {
      state.payPalSubscriptionsScriptStatus = value;
    },
    timestampKeySuffixIncrement(state) {
      state.timestampKeySuffix += 1;
    },
    loggedIn(state, value) {
      state.loggedIn = value;
    },
    userData(state, value) {
      if (value === null) {
        state.userData = JSON.parse(JSON.stringify(userDataDefault));
      } else {
        state.userData = value;
      }
    },
    userMenuProfileImageKeySuffixIncrement(state) {
      state.userMenuProfileImageKeySuffix += 1;
    },
  },
  actions: {
    loadPayPalIPGRegistrationFeeScript(context) {
      context.commit('payPalIPGRegistrationFeeScriptStatus', 'loading');

      const payPalIPGRegistrationFeeScript = document.getElementById('paypal_ipg_registration_fee_script');

      if (payPalIPGRegistrationFeeScript) {
        document.body.removeChild(payPalIPGRegistrationFeeScript);
      }

      const script = document.createElement('script');
      script.id = 'paypal_ipg_registration_fee_script';
      script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.VUE_APP_PAYPAL_CLIENT_ID}&disable-funding=card,credit`;
      script.setAttribute('data-namespace', 'paypalIPG');
      script.onerror = () => {
        context.commit('payPalIPGRegistrationFeeScriptStatus', 'error');
      };
      script.onload = () => {
        context.commit('payPalIPGRegistrationFeeScriptStatus', 'loaded');
      };
      document.body.appendChild(script);
    },
    loadPayPalSubscriptionsScript(context) {
      context.commit('payPalSubscriptionsScriptStatus', 'loading');

      const payPalSubscriptionsScript = document.getElementById('paypal_subscriptions_script');

      if (payPalSubscriptionsScript) {
        document.body.removeChild(payPalSubscriptionsScript);
      }

      const script = document.createElement('script');
      script.id = 'paypal_subscriptions_script';
      script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.VUE_APP_PAYPAL_CLIENT_ID}&vault=true&intent=subscription&disable-funding=card,credit`;
      script.onerror = () => {
        context.commit('payPalSubscriptionsScriptStatus', 'error');
      };
      script.onload = () => {
        context.commit('payPalSubscriptionsScriptStatus', 'loaded');
      };
      document.body.appendChild(script);
    },
  },
  modules: {
  },
});
