
/* eslint-disable vue/no-mutating-props */

import { ApiError } from '@virgodev/bazaar/functions/api';
import { SweetAlertResult } from 'sweetalert2';
import { defineComponent, PropType } from 'vue';
import { mapState } from 'vuex';
import { CheckIcon, XIcon } from '@zhuowenli/vue-feather-icons';
import { NewAttachmentInterface } from '@/interfaces/posts';

export default defineComponent({
  components: {
    CheckIcon,
    XIcon,
  },
  props: {
    attachment: {
      type: Object as PropType<NewAttachmentInterface>,
      required: true,
    },
    chatRoomName: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: [
    'attachmentRemoved',
  ],
  computed: {
    ...mapState([
      'appPlatform',
    ]),
  },
  created() {
    this.uploadAttachment();
  },
  methods: {
    blobToImage(blob: File): Promise<HTMLImageElement> {
      return new Promise((resolve) => {
        const reader = new FileReader();
        const image = new Image();
        image.onload = () => resolve(image);
        reader.onload = (event) => {
          const target = event.target as FileReader;
          image.src = target.result as string;
        };
        reader.readAsDataURL(blob);
      });
    },
    getJpegOrientation(file: File): Promise<number> {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event) => {
          const target = event.target as FileReader;
          const view = new DataView(target.result as ArrayBuffer);

          if (view.getUint16(0, false) !== 0xFFD8) {
            // eslint-disable-next-line prefer-promise-reject-errors
            return reject(-2);
          }

          const length = view.byteLength;
          let offset = 2;

          while (offset < length) {
            const marker = view.getUint16(offset, false);
            offset += 2;

            if (marker === 0xFFE1) {
              // eslint-disable-next-line no-cond-assign
              if (view.getUint32(offset += 2, false) !== 0x45786966) {
                return resolve(-1);
              }
              const little = view.getUint16(offset += 6, false) === 0x4949;
              offset += view.getUint32(offset + 4, little);
              const tags = view.getUint16(offset, little);
              offset += 2;

              for (let i = 0; i < tags; i += 1) {
                if (view.getUint16(offset + (i * 12), little) === 0x0112) {
                  return resolve(view.getUint16(offset + (i * 12) + 8, little));
                }
              }
            } else if ((marker & 0xFF00) !== 0xFF00) { // eslint-disable-line no-bitwise
              break;
            } else {
              offset += view.getUint16(offset, false);
            }
          }
          return resolve(-1);
        };

        reader.readAsArrayBuffer(file.slice(0, 64 * 1024));
      });
    },
    removeAttachment() {
      (this.attachment.controller as AbortController).abort();
      this.$emit('attachmentRemoved');
    },
    removeAttachmentPrompt() {
      if (this.attachment.status === 'Error') {
        this.removeAttachment();
      } else {
        this.$swal.fire({
          title: 'Are You Sure?',
          text: 'Proceed with removing the attachment?',
          customClass: {
            confirmButton: 'btn btn-danger',
            cancelButton: 'btn btn-light',
          },
          showCancelButton: true,
          confirmButtonText: 'Yes, Remove It',
        }).then((result: SweetAlertResult) => {
          if (result.isConfirmed) {
            this.removeAttachment();
          }
        });
      }
    },
    async uploadAttachment() {
      if (
        this.attachment.file.type.slice(0, 5) === 'image'
        && this.attachment.base64Image === undefined
      ) {
        let jpegOrientation = -1;
        let browserHonorsExifOrientation;

        if (this.appPlatform === 'ios') {
          browserHonorsExifOrientation = true;
        } else {
          browserHonorsExifOrientation = CSS.supports('image-orientation: none');
        }

        if (!browserHonorsExifOrientation) {
          try {
            jpegOrientation = await this.getJpegOrientation(this.attachment.file);
          } catch (e) {
            jpegOrientation = e as number;
          }
        }

        const image = await this.blobToImage(this.attachment.file);
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
        const { width, height } = image;

        if (jpegOrientation > 4 && jpegOrientation < 9) {
          canvas.width = height;
          canvas.height = width;
        } else {
          canvas.width = width;
          canvas.height = height;
        }

        switch (jpegOrientation) {
          case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
          case 3: ctx.transform(-1, 0, 0, -1, width, height); break;
          case 4: ctx.transform(1, 0, 0, -1, 0, height); break;
          case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
          case 6: ctx.transform(0, 1, -1, 0, height, 0); break;
          case 7: ctx.transform(0, -1, -1, 0, height, width); break;
          case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
          default: break;
        }

        ctx.drawImage(image, 0, 0, width, height);
        this.attachment.base64Image = canvas.toDataURL('image/jpeg');
      }

      this.attachment.status = 'Uploading';
      this.attachment.controller = new AbortController();

      const body = new FormData();
      body.append('file', this.attachment.file, this.attachment.file.name);

      let url;

      if (this.chatRoomName !== '') {
        url = 'chat/history/upload_attachment/';
        body.append('room', this.chatRoomName);
      } else {
        url = 'upload_attachment/';
      }

      const responseData = await this.api({
        url,
        method: 'POST',
        body,
        options: {
          signal: this.attachment.controller.signal,
        },
      });

      if (responseData.status === 200) {
        this.attachment.id = responseData.body.id;
        this.attachment.status = 'Uploaded';
      } else if (
        !(
          Object.prototype.hasOwnProperty.call(responseData, 'error')
          && (responseData as ApiError).error.name === 'AbortError'
        )
      ) {
        this.attachment.status = 'Error';
      }
    },
  },
});
