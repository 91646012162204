export default function formatCurrency(input: number | string): string {
  let number;

  if (typeof input === 'number') {
    number = input;
  } else {
    number = parseFloat(input);
  }

  return new Intl.NumberFormat(
    'en-us',
    { minimumFractionDigits: 2, maximumFractionDigits: 2 },
  ).format(number);
}
