
/* eslint-disable no-param-reassign */

import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    direction: {
      type: String,
      default: 'vertical',
    },
    duration: {
      type: Number,
      default: 400,
    },
  },
  computed: {
    hooks(): Record<string, unknown> {
      return {
        beforeEnter: this.beforeEnter,
        afterEnter: this.afterEnter,
        beforeLeave: this.beforeLeave,
        afterLeave: this.afterLeave,
      };
    },
    widthOrHeight(): 'width' | 'height' {
      return this.direction === 'vertical' ? 'height' : 'width';
    },
  },
  methods: {
    beforeEnter(el: HTMLElement) {
      el.style[this.widthOrHeight] = '0';
      el.style.transitionDuration = `${this.duration}ms`;
      el.style.transitionProperty = this.widthOrHeight;

      this.$nextTick(() => {
        if (this.widthOrHeight === 'height') {
          el.style.height = `${el.scrollHeight}px`;
        } else {
          el.style.width = `${el.scrollWidth}px`;
        }
      });
    },
    afterEnter(el: HTMLElement) {
      el.style[this.widthOrHeight] = '';
      el.style.transitionDuration = '';
      el.style.transitionProperty = '';
    },
    beforeLeave(el: HTMLElement) {
      if (this.widthOrHeight === 'height') {
        el.style.height = `${el.scrollHeight}px`;
      } else {
        el.style.width = `${el.scrollWidth}px`;
      }

      el.style.transitionDuration = `${this.duration}ms`;
      el.style.transitionProperty = this.widthOrHeight;

      setTimeout(() => {
        el.style[this.widthOrHeight] = '0';
      }, 50);
    },
    afterLeave(el: HTMLElement) {
      el.style[this.widthOrHeight] = '';
      el.style.transitionDuration = '';
      el.style.transitionProperty = '';
    },
  },
});
