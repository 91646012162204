
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    size: {
      type: Number,
      default: 16,
    },
    thickness: {
      type: Number,
      default: 1.5,
    },
    speed: {
      type: Number,
      default: 0.7,
    },
    center: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '#fff',
    },
    preset: {
      type: String,
      default: '',
    },
  },
  data() {
    let center;
    let color;
    let size;
    let thickness;
    let speed;

    if (this.preset === 'large') {
      center = true;
      size = 64;
      ({ color, thickness, speed } = this);
    } else {
      ({
        center, color, size, thickness, speed,
      } = this);
    }

    return {
      centerToUse: center,
      colorToUse: color,
      sizeToUse: size,
      thicknessToUse: thickness,
      speedToUse: speed,
    };
  },
  computed: {
    spinnerStyle(): { [key: string]: string } {
      return {
        display: this.centerToUse ? 'block' : 'inline-block',
        margin: this.centerToUse ? '0 auto' : '0',
      };
    },
  },
});
