<template>
  <div
    :class="`alert alert-${variant}${dismissible ? ' alert-dismissible' : ''}`"
    role="alert"
  >
    <button
      v-if="dismissible"
      type="button"
      class="btn-transparent alert-close"
      aria-label="Dismiss message"
      @click="$emit('dismissed')"
    >
      <x-icon />
    </button>

    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { XIcon } from '@zhuowenli/vue-feather-icons';

export default defineComponent({
  components: {
    XIcon,
  },
  props: {
    variant: {
      type: String,
      required: false,
      default: 'info',
      validator: (value: string) => [
        'info',
        'success',
        'danger',
      ].includes(value),
    },
    dismissible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: [
    'dismissed',
  ],
});
</script>

<style lang="scss" scoped>
  .alert {
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 0.25rem;

    :deep(.alert-link) {
      font-weight: bold;
    }
  }

  .alert-success {
    background-color: var(--green);
    color: #fff;

    :deep(.alert-link) {
      color: var(--green-dark)
    }
  }

  .alert-info {
    background-color: var(--purple);
    color: #fff;

    :deep(.alert-link) {
      color: #fff;
    }
  }

  .alert-danger {
    background-color: var(--red);
    color: #fff;

    :deep(.alert-link) {
      color: var(--red-dark)
    }
  }

  .alert-dismissible {
    position: relative;
    padding-right: 2.5rem;
  }

  .alert-close {
    position: absolute;
    top: 50%;
    right: 14px;
    color: #fff;
    font-size: 18px;
    transform: translateY(-50%);
  }
</style>
