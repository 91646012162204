import { setGlobalRequestHeader } from '@virgodev/bazaar/functions/api';
// @ts-expect-error Could not find a declaration file for module 'mdi-vue/v3'.
import mdiVue from 'mdi-vue/v3';
import * as mdijs from '@mdi/js';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import { createApp } from 'vue';
// @ts-expect-error Could not find a declaration file for module 'v-tooltip'.
import VTooltip from 'v-tooltip';
import VueEasyLightbox from 'vue-easy-lightbox';
import VueGtag from 'vue-gtag';
import VueSweetalert2 from 'vue-sweetalert2';
import App from './App.vue';
import globalComponents from './components';
import globalMethods from './methods';
import router from './router';
import store from './store';

if (
  /(iPad|iPhone|iPod)/g.test(navigator.userAgent)
  || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
) {
  document.body.classList.add('is-ios');
}

if (store.state.drfToken !== null) {
  setGlobalRequestHeader('Authorization', `Token ${store.state.drfToken}`);
}

const app = createApp(App)
  .mixin({
    components: globalComponents,
    computed: {
      loggedIn() { return store.state.loggedIn; },
      userData() { return store.state.userData; },
    },
    methods: globalMethods,
  })
  .use(mdiVue, {
    icons: mdijs,
  })
  .use(VTooltip)
  .use(VueEasyLightbox)
  .use(VueGtag, {
    bootstrap: process.env.NODE_ENV === 'production',
    config: {
      id: 'G-6KVNRW47J3',
    },
  }, router)
  .use(VueSweetalert2, {
    customClass: {
      confirmButton: 'btn btn-primary',
      denyButton: 'btn btn-light',
      cancelButton: 'btn btn-light',
    },
    buttonsStyling: false,
  })
  .use(store);

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    app,
    dsn: 'https://4d9284eb85914abfb81cbac00c6e812d@errors.danemco.net/89',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

app.use(router);
window.app = app.mount('#app');

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $swal: any;
    loggedIn: typeof store.state.loggedIn;
    userData: typeof store.state.userData;
    api: typeof globalMethods.api;
    capitalizeWords: typeof globalMethods.capitalizeWords;
    dateDisplay: typeof globalMethods.dateDisplay;
    formatCurrency: typeof globalMethods.formatCurrency;
    goBackOrToRoute: typeof globalMethods.goBackOrToRoute;
    isObjectEmpty: typeof globalMethods.isObjectEmpty;
    linebreaksbr: typeof globalMethods.linebreaksbr;
    logUserOut: typeof globalMethods.logUserOut;
    pluralize: typeof globalMethods.pluralize;
    scrollToFirstError: typeof globalMethods.scrollToFirstError;
    timestampDisplay: typeof globalMethods.timestampDisplay;
    timestampDistanceDisplay: typeof globalMethods.timestampDistanceDisplay;
    toListSentence: typeof globalMethods.toListSentence;
    updateTextareaHeight: typeof globalMethods.updateTextareaHeight;
  }
}
