export default function logUserOut(redirectOrReload: 'redirect' | 'reload' = 'reload'): void {
  window.app.$store.commit('drfToken', null);
  window.app.$store.commit('loggedIn', false);
  window.app.$store.commit('userData', null);

  if (window.app.wsSocket) {
    window.app.wsSocket.close();
  }

  if (redirectOrReload === 'redirect') {
    let router;

    import('../router').then(async (res) => {
      router = res.default;

      if (router.currentRoute.value.name !== 'login') {
        await router.push({ name: 'login' });
      }
    });
  } else {
    window.location.reload();
  }
}
