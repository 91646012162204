
import { ApiError } from '@virgodev/bazaar/functions/api';
import debounce from 'debounce';
import { defineComponent } from 'vue';
import { SearchIcon, UserIcon } from '@zhuowenli/vue-feather-icons';
import ProfileImage from '@/components/users/ProfileImage.vue';
import { GameInterface } from '@/interfaces/games';
import { TeamInterface } from '@/interfaces/teams';
import { UserDirectoryResultInterface } from '@/interfaces/users';

export default defineComponent({
  components: {
    SearchIcon,
    UserIcon,
    ProfileImage,
  },
  data: () => ({
    abortController: null as null | AbortController,
    searchKeywords: '',
    searchType: 'users' as 'users' | 'teams' | 'games',
    searchResultsStatus: 'idle' as 'idle' | 'loading' | 'loaded' | 'error',
    searchResults: {} as {
      count: number,
      next: string | null,
      previous: string | null,
      results: Array<UserDirectoryResultInterface | TeamInterface | GameInterface>,
    },
    moreSearchResultsStatus: 'idle' as 'idle' | 'loading' | 'loaded' | 'error',
    observer: null as null | IntersectionObserver,
  }),
  computed: {
    showSearchResults() {
      return window.app.openMenu === 'search_results';
    },
  },
  watch: {
    searchType() {
      this.loadSearchResults(false);
    },
  },
  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    getGameSearchResults() {
      return this.searchResults.results as Array<GameInterface>;
    },
    getTeamSearchResults() {
      return this.searchResults.results as Array<TeamInterface>;
    },
    getUserSearchResults() {
      return this.searchResults.results as Array<UserDirectoryResultInterface>;
    },
    async loadSearchResults(includeDelay: boolean) {
      if (includeDelay) {
        // This delay is here for situations in which a click would undesirably
        // cause the search results to close.
        await new Promise((resolve) => setTimeout(resolve, 0));
      }

      if (this.abortController) {
        this.abortController.abort();
      }

      const searchKeywords = this.searchKeywords.trim();

      if (searchKeywords === '') {
        window.app.openMenu = '';
        this.searchResultsStatus = 'idle';
        this.searchResults = {
          count: 0,
          next: null,
          previous: null,
          results: [],
        };
        return;
      }

      window.app.openMenu = 'search_results';

      this.searchResultsStatus = 'loading';
      this.abortController = new AbortController();

      const responseData = await this.api({
        url: `${this.searchType}/search/?q=${searchKeywords}`,
        options: {
          signal: this.abortController.signal,
        },
      });

      if (responseData.status === 200) {
        this.searchResults = responseData.body;
        this.searchResultsStatus = 'loaded';

        if (this.searchResults.next) {
          this.$nextTick(() => {
            this.observer = new IntersectionObserver((entries) => {
              entries.forEach((entry) => {
                if (entry.intersectionRatio) {
                  this.loadMoreSearchResults(false);
                }
              });
            });

            this.observer.observe(this.$refs.loadMoreSearchResultsButton as HTMLButtonElement);
          });
        }
      } else if (
        !(
          Object.prototype.hasOwnProperty.call(responseData, 'error')
          && (responseData as ApiError).error.name === 'AbortError'
        )
      ) {
        this.searchResultsStatus = 'error';
      }
    },
    async loadMoreSearchResults(includeDelay: boolean) {
      if (includeDelay) {
        // This delay is here for situations in which a click would undesirably
        // cause the search results to close.
        await new Promise((resolve) => setTimeout(resolve, 0));
      }

      if (this.abortController) {
        this.abortController.abort();
      }

      this.moreSearchResultsStatus = 'loading';
      this.abortController = new AbortController();

      const responseData = await this.api({
        url: this.searchResults.next as string,
        options: {
          signal: this.abortController.signal,
        },
      });

      if (responseData.status === 200) {
        this.searchResults.count = responseData.body.count;
        this.searchResults.next = responseData.body.next;
        this.searchResults.previous = responseData.body.previous;
        this.searchResults.results = this.searchResults.results.concat(
          responseData.body.results,
        );
        this.moreSearchResultsStatus = 'idle';
      } else if (
        !(
          Object.prototype.hasOwnProperty.call(responseData, 'error')
          && (responseData as ApiError).error.name === 'AbortError'
        )
      ) {
        this.moreSearchResultsStatus = 'error';
      }
    },
    searchFieldFocus() {
      if (this.searchResultsStatus !== 'idle') {
        window.app.openMenu = 'search_results';
      }
    },
    // eslint-disable-next-line func-names
    searchFieldInput: debounce(function () {
      // @ts-expect-error 'this' implicitly has type 'any' because it does not
      // have a type annotation.
      this.loadSearchResults(false);
    }, 300),
  },
});
