export default function scrollToFirstError(element: HTMLElement | undefined = undefined): void {
  let elementToScroll;

  if (element) {
    elementToScroll = element;
  } else {
    // eslint-disable-next-line no-param-reassign
    element = document.documentElement;
    elementToScroll = window;
  }

  const firstError = element.querySelector('.js-form-error');

  if (firstError) {
    const domRect = firstError.getBoundingClientRect();
    elementToScroll.scrollTo({
      top: domRect.top + element.scrollTop - 140,
      left: domRect.left + element.scrollLeft,
      behavior: 'smooth',
    });
  }
}
