
import { defineComponent } from 'vue';
import { XIcon } from '@zhuowenli/vue-feather-icons';

export default defineComponent({
  components: {
    XIcon,
  },
  props: {
    variant: {
      type: String,
      required: false,
      default: 'info',
      validator: (value: string) => [
        'info',
        'success',
        'danger',
      ].includes(value),
    },
    dismissible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: [
    'dismissed',
  ],
});
