export default function updateTextareaHeight(e: KeyboardEvent | ClipboardEvent): void {
  const target = e.target as HTMLTextAreaElement;

  if (target.offsetHeight < target.scrollHeight) {
    const newHeight = target.scrollHeight - 3;
    target.style.height = (newHeight > 128) ? '128px' : `${newHeight}px`;
  } else if (target.offsetHeight > target.scrollHeight) {
    target.style.height = '64px';
  }
}
