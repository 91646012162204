<template>
  <div
    id="notifications-component"
    class="component"
  >
    <button
      type="button"
      class="btn-transparent header-button"
      aria-controls="notifications-menu"
      aria-label="Toggle notifications menu"
      :aria-expanded="open"
      :tabindex="buttonTabindex"
      @click="$emit('buttonClicked')"
    >
      <div class="button-content">
        <bell-icon />
        <div
          v-if="showHeaderButtonUnreadDot"
          class="header-button-unread-dot"
        />
      </div>
    </button>

    <div
      v-show="open"
      id="notifications-menu"
      class="menu"
    >
      <div class="menu-header">
        <h2>Notifications</h2>

        <spinner
          v-if="notificationsStatus === 'loading'"
          center
        />

        <template v-else-if="notificationsStatus === 'error'">
          <alert variant="danger">
            Unable to load your notifications. Please check your connection and
            try again.
          </alert>

          <button
            type="button"
            class="btn btn-outline-primary"
            @click="loadNotifications"
          >
            Try Again
          </button>
        </template>

        <template v-else-if="notificationsStatus === 'loaded'">
          <alert
            v-if="markAllAsReadStatus === 'error'"
            variant="danger"
          >
            Unable to mark all notifications as read. Please check your
            connection and try again.
          </alert>

          <div
            v-if="notifications.results.length"
            class="buttons"
          >
            <button
              type="button"
              class="btn btn-outline-light btn-xs mark-all-as-read-button"
              :disabled="markAllAsReadStatus === 'submitting'"
              @click="markAllAsRead"
            >
              <spinner v-if="markAllAsReadStatus === 'submitting'" />

              <template v-else>
                <check-icon />
                Mark All as Read
              </template>
            </button>

            <router-link
              :to="{ name: 'settings_notifications' }"
              class="btn btn-outline-light btn-xs"
            >
              <settings-icon />
              Notification Settings
            </router-link>
          </div>

          <p
            v-else
            class="no-notifications"
          >
            You have no notifications.
          </p>
        </template>
      </div>

      <div
        v-if="notificationsStatus === 'loaded' && notifications.results.length"
        class="notifications subtle-scrollbar"
      >
        <div
          v-for="notification of notifications.results"
          :key="`notification${notification.id}`"
          :class="
            `notification
            notification-${!notification.clicked && !notification.marked_as_read ? 'un' : ''}read`
          "
        >
          <router-link
            :to="getNotificationLink(notification)"
            @click="notificationClickHandler(notification)"
          >
            <profile-image :user="notification.data.user" />

            <div class="notification-html-and-timestamp">
              <div>
                {{ getUserText(notification) }}

                <template v-if="notification.notification_type === 'post_comment'">
                  commented on your post

                  <template v-if="notification.data.body !== ''">
                    "{{ notification.data.body }}"
                  </template>
                </template>

                <template v-else-if="notification.notification_type === 'post_like'">
                  liked your post

                  <template v-if="notification.data.body !== ''">
                    "{{ notification.data.body }}"
                  </template>
                </template>

                <template v-else-if="notification.notification_type === 'comment_reply'">
                  replied to your comment

                  <template v-if="notification.data.body !== ''">
                    "{{ notification.data.body }}"
                  </template>
                </template>

                <template v-else-if="notification.notification_type === 'comment_like'">
                  liked your comment

                  <template v-if="notification.data.body !== ''">
                    "{{ notification.data.body }}"
                  </template>
                </template>

                <template
                  v-else-if="notification.notification_type === 'team_join_request_received'"
                >
                  has requested to join the "{{ notification.data.team_name }}" team
                </template>

                <template
                  v-else-if="notification.notification_type === 'team_join_invitation_accepted'"
                >
                  has accepted your invitation to join the "{{ notification.data.team_name }}" team
                </template>

                <template
                  v-else-if="notification.notification_type === 'team_join_invitation_declined'"
                >
                  has declined your invitation to join the "{{ notification.data.team_name }}" team
                </template>

                <template
                  v-else-if="notification.notification_type === 'team_join_invitation_received'"
                >
                  has invited you to join the "{{ notification.data.team_name }}" team
                </template>

                <template
                  v-else-if="notification.notification_type === 'team_join_request_approved'"
                >
                  has approved your request to join the "{{ notification.data.team_name }}" team
                </template>

                <template v-else-if="notification.notification_type === 'team_join_request_denied'">
                  has denied your request to join the "{{ notification.data.team_name }}" team
                </template>
              </div>

              <p
                :key="`timestamp${$store.state.timestampKeySuffix}`"
                class="timestamp"
              >
                {{ timestampDistanceDisplay(notification.updated) }}
              </p>
            </div>
          </router-link>

          <div class="notification-right">
            <button
              v-tooltip.left="
                `Mark as ${!notification.clicked && !notification.marked_as_read ? '' : 'un'}read`
              "
              :aria-label="
                `Mark as ${!notification.clicked && !notification.marked_as_read ? '' : 'un'}read`
              "
              type="button"
              class="btn-transparent"
              :disabled="
                markAllAsReadStatus === 'submitting'
                  || ['toggling_read_status', 'deleting'].includes(notification.status)
              "
              @click.stop="toggleNotificationReadStatus(notification)"
            >
              <spinner v-if="notification.status === 'toggling_read_status'" />

              <check-icon v-else />
            </button>

            <button
              v-tooltip.left="'Delete notification'"
              aria-label="Delete notification"
              type="button"
              class="btn-transparent"
              :disabled="
                markAllAsReadStatus === 'submitting'
                  || ['toggling_read_status', 'deleting'].includes(notification.status)
              "
              @click.stop="deleteNotification(notification.id)"
            >
              <spinner v-if="notification.status === 'deleting'" />

              <trash2-icon v-else />
            </button>

            <div
              v-if="!notification.clicked && !notification.marked_as_read"
              class="notification-unread-dot"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import {
  BellIcon,
  CheckIcon,
  SettingsIcon,
  Trash2Icon,
} from '@zhuowenli/vue-feather-icons';
import ProfileImage from '@/components/users/ProfileImage.vue';
import { NotificationInterface, NotificationWithStatusInterface } from '@/interfaces/notifications';

export default defineComponent({
  components: {
    BellIcon,
    CheckIcon,
    SettingsIcon,
    Trash2Icon,
    ProfileImage,
  },
  props: {
    buttonTabindex: {
      type: Number,
      default: 0,
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'buttonClicked',
  ],
  data: () => ({
    notificationsStatus: 'idle' as 'idle' | 'loading' | 'loaded' | 'error',
    notifications: {} as {
      next: string | null,
      previous: string | null,
      results: Array<NotificationWithStatusInterface>,
    },
    showHeaderButtonUnreadDot: false,
    markAllAsReadStatus: 'idle' as 'idle' | 'submitting' | 'error',
    socket: null as null | WebSocket,
    socketStatus: 'idle' as 'idle' | 'connected' | 'reconnecting' | 'disconnected',
    socketReconnectData: null as null | {
      attempt: number,
      countdown: number,
      intervalID: number,
    },
    wsServer: `${process.env.VUE_APP_API_URL}../ws/notifications/`
      .replace('http://', 'ws://')
      .replace('https://', 'wss://'),
  }),
  watch: {
    open() {
      if (this.open && this.notificationsStatus !== 'loading') {
        this.loadNotifications();
      }
    },
  },
  created() {
    this.connectToServer();
  },
  methods: {
    connectToServer() {
      if (this.socket !== null) {
        this.socketStatus = 'reconnecting';
      }

      if (this.socketReconnectData) {
        clearInterval(this.socketReconnectData.intervalID);
      }

      this.socket = new WebSocket(this.wsServer);

      this.socket.onopen = async () => {
        this.socketStatus = 'connected';
        this.socketReconnectData = null;

        this.sendOnWebsocket({
          type: 'login',
          authentication: `Token ${this.$store.state.drfToken}`,
        }, true);
      };

      this.socket.onmessage = async (event) => {
        const data = JSON.parse(event.data);

        if (data.type === 'login') {
          if (data.ok) {
            if (data.unseen_count && !this.open) {
              this.showHeaderButtonUnreadDot = true;
            }
          } else {
            this.logUserOut('reload');
          }
        } else if (data.type === 'delivery') {
          if (this.open) {
            this.loadNotifications();
          } else {
            this.showHeaderButtonUnreadDot = data.unseen_count > 0;
          }
        }
      };

      this.socket.onclose = (event) => {
        if (event.wasClean) {
          return;
        }

        if (this.socketReconnectData) {
          this.socketReconnectData.attempt += 1;
          this.socketReconnectData.countdown = this.socketReconnectData.attempt * 2;
        } else {
          this.socketReconnectData = {
            attempt: 1,
            countdown: 3,
            intervalID: 0,
          };
        }

        this.socketReconnectData.intervalID = setInterval(this.socketReconnectCountdown, 1000);
        this.socketStatus = 'disconnected';
      };
    },
    socketReconnectCountdown() {
      if (this.socketReconnectData) {
        this.socketReconnectData.countdown -= 1;

        if (!this.socketReconnectData.countdown) {
          clearInterval(this.socketReconnectData.intervalID);
          this.connectToServer();
        }
      }
    },
    sendOnWebsocket(data: any, json = true) {
      if (this.socket) {
        if (json) {
          // eslint-disable-next-line no-param-reassign
          data = JSON.stringify(data);
        }

        this.socket.send(data);
      }
    },
    async deleteNotification(notificationId: number) {
      this.setNotificationStatus(notificationId, 'deleting');

      const responseData = await this.api({
        url: `notifications/${notificationId}/`,
        method: 'DELETE',
      });

      if (responseData.status === 204) {
        this.notifications.results = this.notifications.results.filter(
          (n) => n.id !== notificationId,
        );
      } else {
        this.setNotificationStatus(notificationId, 'idle');
        const title = 'Failed to Delete Notification';
        let text;

        if (responseData.status === 404) {
          text = 'This notification no longer exists.';
        } else {
          text = 'Please check your connection and try again.';
        }

        this.$swal(title, text);
      }
    },
    getNotificationLink(notification: NotificationWithStatusInterface) {
      const to = {
        name: '',
        params: {} as Record<string, any>,
      };

      if (notification.notification_type === 'post_comment') {
        if (notification.data.team_slug === '') {
          to.name = 'user_comment';
          to.params.username = this.userData.username;
          to.params.postId = notification.object_id;
          to.params.commentId = notification.data.comment_id;
        } else {
          to.name = 'team_comment';
          to.params.teamSlug = notification.data.team_slug;
          to.params.postId = notification.object_id;
          to.params.commentId = notification.data.comment_id;
        }
      } else if (notification.notification_type === 'post_like') {
        if (notification.data.team_slug === '') {
          to.name = 'user_post';
          to.params.username = this.userData.username;
          to.params.postId = notification.object_id;
        } else {
          to.name = 'team_post';
          to.params.teamSlug = notification.data.team_slug;
          to.params.postId = notification.object_id;
        }
      } else if (notification.notification_type === 'comment_reply') {
        if (notification.data.team_slug === '') {
          to.name = 'user_comment';
          to.params.username = this.userData.username;
          to.params.postId = notification.data.post_id;
          to.params.commentId = notification.data.comment_id;
        } else {
          to.name = 'team_comment';
          to.params.teamSlug = notification.data.team_slug;
          to.params.postId = notification.data.post_id;
          to.params.commentId = notification.data.comment_id;
        }
      } else if (notification.notification_type === 'comment_like') {
        if (notification.data.team_slug === '') {
          to.name = 'user_comment';
          to.params.username = this.userData.username;
          to.params.postId = notification.data.post_id;
          to.params.commentId = notification.object_id;
        } else {
          to.name = 'team_comment';
          to.params.teamSlug = notification.data.team_slug;
          to.params.postId = notification.data.post_id;
          to.params.commentId = notification.object_id;
        }
      } else if (notification.notification_type === 'team_join_request_received') {
        to.name = 'team_join_requests';
        to.params.teamSlug = notification.data.team_slug;
      } else if (notification.notification_type === 'team_join_invitation_accepted') {
        to.name = 'team_members';
        to.params.teamSlug = notification.data.team_slug;
      } else if ([
        'team_join_invitation_declined',
        'team_join_invitation_received',
        'team_join_request_approved',
        'team_join_request_denied',
      ].includes(notification.notification_type)) {
        to.name = 'team';
        to.params.teamSlug = notification.data.team_slug;
      }

      return to;
    },
    getUserText(notification: NotificationWithStatusInterface) {
      let userText = `${notification.data.user.first_name} ${notification.data.user.last_name}`;

      if (notification.data.total_other_users) {
        userText += ` and ${notification.data.total_other_users} other`;

        if (notification.data.total_other_users > 1) {
          userText += 's';
        }
      }

      return userText;
    },
    async loadNotifications() {
      this.notificationsStatus = 'loading';

      const responseData = await this.api({
        url: 'notifications/',
      });

      if (responseData.status === 200) {
        const notifications = responseData.body;
        const modifiedResults = [] as Array<NotificationWithStatusInterface>;

        notifications.results.forEach((notification: NotificationInterface) => {
          const modifiedResult = JSON.parse(JSON.stringify(notification));
          modifiedResult.status = 'idle';
          modifiedResults.push(modifiedResult);
        });

        notifications.results = modifiedResults;
        this.notifications = notifications;
        this.notificationsStatus = 'loaded';
        this.showHeaderButtonUnreadDot = false;
      } else {
        this.notificationsStatus = 'error';
      }
    },
    async markAllAsRead() {
      this.markAllAsReadStatus = 'submitting';

      const responseData = await this.api({
        url: 'notifications/mark_all_as_read/',
        method: 'POST',
      });

      if (responseData.status === 204) {
        this.markAllAsReadStatus = 'idle';
        this.notifications.results.forEach((notification) => {
          // eslint-disable-next-line no-param-reassign
          notification.marked_as_read = 'yes';
        });
      } else {
        this.markAllAsReadStatus = 'error';
      }
    },
    notificationClickHandler(notification: NotificationWithStatusInterface) {
      if (!notification.clicked) {
        // eslint-disable-next-line no-param-reassign
        notification.clicked = 'yes';

        this.api({
          url: `notifications/${notification.id}/mark_as_clicked/`,
          method: 'PATCH',
        });
      }
    },
    setNotificationStatus(notificationId: number, status: 'idle' | 'toggling_read_status' | 'deleting') {
      const notifications = this.notifications.results;
      const notification = notifications.filter((n) => n.id === notificationId)[0];
      notification.status = status;
    },
    async toggleNotificationReadStatus(notification: NotificationWithStatusInterface) {
      this.setNotificationStatus(notification.id, 'toggling_read_status');

      const json = { read: true };

      if (notification.clicked || notification.marked_as_read) {
        json.read = false;
      }

      const responseData = await this.api({
        url: `notifications/${notification.id}/change_read_status/`,
        method: 'PATCH',
        json,
      });

      this.setNotificationStatus(notification.id, 'idle');

      if (responseData.status === 204) {
        if (json.read) {
          // eslint-disable-next-line no-param-reassign
          notification.marked_as_read = 'yes';
        } else {
          // eslint-disable-next-line no-param-reassign
          notification.marked_as_read = null;
          // eslint-disable-next-line no-param-reassign
          notification.clicked = null;
        }
      } else {
        const title = `Failed to Mark Notification as ${json.read ? 'Read' : 'Unread'}`;
        let text;

        if (responseData.status === 404) {
          text = 'This notification no longer exists.';
        } else {
          text = 'Please check your connection and try again.';
        }

        this.$swal(title, text);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
  .component {
    position: relative;
  }

  .button-content {
    position: relative;
  }

  .feather-bell {
    stroke: var(--gray-light);
  }

  .menu {
    position: absolute;
    right: -70px;
    top: calc(100% + 6px);
    width: 290px;
    background-color: var(--gray-darker);
    border-radius: 10px;
    box-shadow: 0 0 2px 4px var(--gray-a50);
  }

  .menu-header {
    padding: 15px;
  }

  .buttons {
    display: flex;
  }

  .mark-all-as-read-button {
    width: 116px;
  }

  .no-notifications {
    margin-bottom: 0;
    text-align: center;
  }

  .notifications {
    margin-top: 0.5rem;
    max-height: 50vh;
    border-top: 1px solid var(--gray);
    overflow-y: auto;
    overscroll-behavior-y: contain;
  }

  .notification {
    position: relative;

    + .notification {
      border-top: 1px solid var(--gray);
    }

    a {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: auto 1fr auto;
      padding: 15px;
      transition: background-color 0.15s ease-in-out;

      &:focus,
      &:hover {
        background-color: var(--gray-dark);
      }

      &:focus {
        outline: 0;
      }

      &:hover {
        text-decoration: none;
      }
    }

    &:last-child a {
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }

  .notification-html-and-timestamp {
    padding-right: 20px;
  }

  .notification-unread {
    a {
      min-height: 113px; // There should always be enough height for .notification-right
      color: #fff;
    }

    .timestamp {
      color: var(--blue);
    }
  }

  .notification-read a {
    min-height: 85px; // There should always be enough height for .notification-right
  }

  .timestamp {
    margin: 0.5rem 0 0;
    font-size: 80%;
  }

  .notification-right {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 5px;

    button {
      display: block;
      padding: 5px;

      + button {
        margin-top: 0.5rem;
      }
    }
  }

  .notification-unread-dot {
    margin: 1rem auto 0;
    width: 12px;
    height: 12px;
    background-color: var(--blue);
    border-radius: 50%;
  }

  @media (min-width: 480px) {
    .menu {
      right: 0;
    }
  }
</style>
