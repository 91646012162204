<template>
  <div
    id="user-menu-component"
    class="component"
  >
    <button
      type="button"
      class="btn-transparent header-button"
      aria-controls="user-menu"
      aria-label="Toggle user menu"
      :aria-expanded="open"
      :tabindex="buttonTabindex"
      @click="$emit('buttonClicked')"
    >
      <profile-image
        :key="`userMenuProfileImage${$store.state.userMenuProfileImageKeySuffix}`"
        :size="36"
      />
    </button>

    <div
      v-show="open"
      id="user-menu"
      class="menu"
    >
      <router-link :to="{ name: 'user_profile', params: { username: userData.username } }">
        <user-icon />
        View Profile
      </router-link>
      <router-link :to="{ name: 'settings_account' }">
        <settings-icon /> Settings
      </router-link>
      <router-link :to="{ name: 'logout' }">
        <log-out-icon /> Log Out
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { LogOutIcon, UserIcon, SettingsIcon } from '@zhuowenli/vue-feather-icons';
import ProfileImage from '@/components/users/ProfileImage.vue';

export default defineComponent({
  components: {
    LogOutIcon,
    UserIcon,
    SettingsIcon,
    ProfileImage,
  },
  props: {
    buttonTabindex: {
      type: Number,
      default: 0,
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'buttonClicked',
  ],
});
</script>

<style lang="scss" scoped>
  .component {
    position: relative;
  }

  .menu {
    position: absolute;
    right: 0;
    top: calc(100% + 6px);
    width: 290px;
    max-height: 50vh;
    background-color: var(--gray-darker);
    border-radius: 10px;
    box-shadow: 0 0 2px 4px var(--gray-a50);
    overflow-y: auto;
    overscroll-behavior-y: contain;
  }

  a {
    display: block;
    padding: 15px;
    color: #fff;

    &:focus,
    &:hover {
      background-color: var(--gray-dark);
    }

    &:focus {
      outline: 0;
    }

    &:hover {
      text-decoration: none;
    }

    + a {
      border-top: 1px solid var(--gray);
    }
  }

  svg {
    margin-right: 0.5em;
  }
</style>
