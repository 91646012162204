<template>
  <div
    :class="{ 'app-not-yet-launched-content': appStatus !== 'launched' }"
    @click="closeMenusIfNecessary"
  >
    <spinner
      v-if="appStatus === 'launching'"
      preset="large"
    />

    <template
      v-else-if="appStatus === 'error'"
      class="horizontal-padding app-launch-failed-content"
    >
      <alert variant="danger">
        An error occurred communicating with the server. Please check your
        connection and try again.
      </alert>

      <button
        type="button"
        class="btn btn-outline-primary"
        @click="launchApp"
      >
        Try Again
      </button>
    </template>

    <template v-else-if="loggedIn && userData.signup_data === undefined">
      <header>
        <router-link
          :to="{ name: 'feed' }"
          class="header-feed-link"
          :tabindex="headerSearchShowingOnNarrowScreens ? -1 : 0"
        >
          <img
            src="./assets/logo.svg"
            alt="The Players Arena"
          >
        </router-link>

        <button
          type="button"
          class="btn-transparent header-button header-menu-button"
          aria-controls="sidebar"
          aria-label="Toggle menu"
          :aria-expanded="sidebarShowingOnNarrowScreens"
          :tabindex="headerSearchShowingOnNarrowScreens ? -1 : 0"
          @click="headerMenuButtonClicked"
        >
          <menu-icon />
        </button>

        <div>
          <button
            type="button"
            class="btn-transparent header-button header-search-show-button"
            aria-controls="header-search-container"
            aria-label="Show search form"
            :aria-expanded="headerSearchShowingOnNarrowScreens"
            :tabindex="headerSearchShowingOnNarrowScreens ? -1 : 0"
            @click="headerSearchShowButtonClicked"
          >
            <search-icon />
          </button>

          <div
            v-show="viewportWidthAtLeast1024 || headerSearchShowingOnNarrowScreens"
            id="header-search-container"
            class="header-search-container"
          >
            <search ref="search" />

            <button
              type="button"
              class="btn-transparent header-button header-search-hide-button"
              aria-controls="header-search-container"
              aria-label="Hide search form"
              :aria-expanded="headerSearchShowingOnNarrowScreens"
              @click="headerSearchShowingOnNarrowScreens = false"
            >
              <x-icon />
            </button>
          </div>
        </div>

        <chat
          :button-tabindex="
            !viewportWidthAtLeast1024 && headerSearchShowingOnNarrowScreens ? -1 : 0
          "
          :open="openMenu === 'chat'"
          @buttonClicked="chatButtonClicked"
          @chatRoomClicked="openChatRoom"
        />

        <notifications
          :button-tabindex="
            !viewportWidthAtLeast1024 && headerSearchShowingOnNarrowScreens ? -1 : 0
          "
          :open="openMenu === 'notifications'"
          @buttonClicked="notificationsButtonClicked"
        />

        <user-menu
          :button-tabindex="
            !viewportWidthAtLeast1024 && headerSearchShowingOnNarrowScreens ? -1 : 0
          "
          :open="openMenu === 'user'"
          @buttonClicked="userButtonClicked"
        />
      </header>

      <transition-slide
        mode="out-in"
        :duration="sidebarTransitionDuration"
        @afterEnter="sidebarTransitionDuration = 0"
        @afterLeave="sidebarTransitionDuration = 0"
      >
        <div
          v-show="viewportWidthAtLeast1024 || sidebarShowingOnNarrowScreens"
          id="sidebar"
          class="sidebar horizontal-padding subtle-scrollbar"
        >
          <router-link
            :to="{ name: 'feed' }"
            class="sidebar-feed-link"
          >
            <img
              src="./assets/logo-with-text.svg"
              alt="The Players Arena"
            >
          </router-link>

          <nav aria-label="Main">
            <ul>
              <li>
                <router-link :to="{ name: 'feed' }">
                  <mdicon
                    name="comment-quote-outline"
                    size="16"
                  />
                  Feed
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: 'user_profile', params: { username: userData.username } }"
                >
                  <user-icon />
                  Profile
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'events' }">
                  <calendar-icon /> Events
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'my_teams' }">
                  <mdicon
                    name="account-group-outline"
                    size="16"
                  />
                  Teams
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'contact' }">
                  <mail-icon />
                  Contact Us
                </router-link>
              </li>
            </ul>
          </nav>

          <div class="sidebar-business">
            <h2 id="sidebar-business-heading">
              Business Portal
            </h2>

            <nav
              v-if="userData.ipg_status !== 'not_ipg'"
              aria-labelledby="sidebar-business-heading"
            >
              <ul>
                <li>
                  <router-link :to="{ name: 'ipg_ewallet_transactions' }">
                    <dollar-sign-icon />
                    eWallet
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'ipg_invitations' }">
                    <user-plus-icon />
                    Invite a Friend
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'ipg_downline' }">
                    <hexagon-icon />
                    Downline
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'ipg_mentorship' }">
                    <users-icon />
                    Mentorship
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'ipg_training_index' }">
                    <mdicon
                      name="school-outline"
                      size="16"
                    />
                    Training
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'ipg_documents' }">
                    <mdicon
                      name="file-document-multiple-outline"
                      size="16"
                    />
                    Documents
                  </router-link>
                </li>
              </ul>
            </nav>

            <template v-else>
              <router-link
                :to="{ name: 'settings_ipg' }"
                class="ipg-registration-link"
              >Register as an Independent Professional Gamer</router-link> <!-- eslint-disable-line vue/multiline-html-element-content-newline, max-len -->
              to gain access to your eWallet, downline, and more!
            </template>
          </div>
        </div>
      </transition-slide>

      <main>
        <router-view />

        <chat-conversation
          v-if="activeChatRoomName !== ''"
          ref="chatConversation"
          :key="`chatRoom${activeChatRoomName}`"
          :user="userData"
          @chatClosed="activeChatRoomName = ''"
        />
      </main>
    </template>

    <template v-else>
      <header>
        <a
          href="/"
          class="header-logo-link"
        >
          <img
            src="./assets/logo-with-text.svg"
            alt="The Players Arena"
          >
        </a>

        <nav>
          <ul>
            <template v-if="loggedIn">
              <li>
                <router-link
                  :to="{ name: 'onboard' }"
                  class="btn btn-primary"
                >
                  Account Setup
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: 'logout' }"
                  class="btn btn-outline-primary"
                >
                  Log Out
                </router-link>
              </li>
            </template>

            <template v-else>
              <li v-if="$route.name !== 'signup'">
                <router-link
                  :to="{ name: 'signup' }"
                  class="btn btn-primary"
                >
                  Sign Up
                </router-link>
              </li>
              <li v-if="$route.name !== 'login'">
                <router-link
                  :to="{ name: 'login' }"
                  class="btn btn-outline-primary"
                >
                  Log In
                </router-link>
              </li>
            </template>
          </ul>
        </nav>
      </header>

      <main>
        <router-view />
      </main>

      <footer>
        <div>
          <span>
            &copy;
            The Players Arena
            {{ new Date().getFullYear() }}
          </span>

          <nav>
            <ul>
              <li>
                <router-link :to="{ name: 'document', params: { documentSlug: 'privacy-policy' } }">
                  Privacy Policy
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'document', params: { documentSlug: 'terms-service' } }">
                  Terms of Use
                </router-link>
              </li>
            </ul>
          </nav>
        </div>
      </footer>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
// eslint-disable-next-line max-len
// @ts-expect-error Could not find a declaration file for module '@virgodev/chat/mixins/ChatAppMixin'.
import ChatAppMixin from '@virgodev/chat/mixins/ChatAppMixin';
import {
  CalendarIcon,
  DollarSignIcon,
  HexagonIcon,
  MailIcon,
  MenuIcon,
  SearchIcon,
  UserIcon,
  UserPlusIcon,
  UsersIcon,
  XIcon,
} from '@zhuowenli/vue-feather-icons';
import Chat from '@/components/chat/Chat.vue';
import ChatConversation from '@/components/chat/ChatConversation.vue';
import Notifications from '@/components/Notifications.vue';
import Search from '@/components/Search.vue';
import TransitionSlide from '@/components/TransitionSlide.vue';
import UserMenu from '@/components/UserMenu.vue';

export default defineComponent({
  components: {
    CalendarIcon,
    DollarSignIcon,
    HexagonIcon,
    MailIcon,
    MenuIcon,
    SearchIcon,
    UserIcon,
    UserPlusIcon,
    UsersIcon,
    Chat,
    ChatConversation,
    Notifications,
    Search,
    TransitionSlide,
    UserMenu,
    XIcon,
  },
  mixins: [
    ChatAppMixin,
  ],
  data() {
    let keyboardType;

    // We will check to see if this device's primary input mechanism can
    // conveniently hover over elements and use that to decide if this device's
    // keyboard is physical or virtual. This is not always going to be
    // accurate, but it should be accurate most of the time.

    if (window.matchMedia('(hover: hover)').matches) {
      keyboardType = 'physical';
    } else {
      keyboardType = 'virtual';
    }

    return {
      appStatus: 'launching' as 'launching' | 'launched' | 'error',
      headerSearchShowingOnNarrowScreens: false,
      sidebarShowingOnNarrowScreens: false,
      sidebarTransitionDuration: 0,
      viewportWidthAtLeast1024: false,
      openMenu: '' as '' | 'search_results' | 'chat' | 'notifications' | 'user',
      activeModals: [] as Array<HTMLDialogElement>,
      keyboardType,
      activeChatRoomName: '',
      wsServer: `${process.env.VUE_APP_API_URL}../ws/chat/`,
      wsToken: null as string | null,
    };
  },
  watch: {
    $route() {
      this.openMenu = '';

      if (this.loggedIn && this.userData.signup_data !== undefined) {
        const currentRoute = this.$router.currentRoute.value.name as string;

        if (![
          '404',
          'document',
          'logout',
          'onboard',
        ].includes(currentRoute)) {
          this.$router.push({ name: 'onboard' });
        }
      }
    },
    loggedIn() {
      this.setHtmlClass();

      if (this.loggedIn) {
        this.wsToken = this.$store.state.drfToken;
      } else {
        this.wsToken = null;
      }
    },
  },
  created() {
    this.launchApp();

    const mql = window.matchMedia('(min-width: 1024px)');

    if (mql.matches) {
      this.viewportWidthAtLeast1024 = true;
    }

    mql.addEventListener('change', (e) => {
      this.viewportWidthAtLeast1024 = e.matches;
    });

    document.documentElement.addEventListener('keydown', (e) => {
      if (e.key === 'Escape' && this.activeModals.length) {
        e.preventDefault();
        const activeModal = this.activeModals.pop() as HTMLDialogElement;
        const activeModalCloseButton = activeModal.querySelector('.js-modal-close') as HTMLButtonElement;
        activeModalCloseButton.click();
      }
    });

    setInterval(() => {
      this.$store.commit('timestampKeySuffixIncrement');
    }, 60000);
  },
  methods: {
    chatButtonClicked() {
      if (this.openMenu === 'chat') {
        this.openMenu = '';
      } else {
        this.openMenu = 'chat';
      }
    },
    closeMenusIfNecessary(e: PointerEvent) {
      if (e.target) {
        const target = e.target as HTMLElement;

        if (target.closest(
          '#search-component, '
          + '#chat-component, '
          + '#notifications-component, '
          + '#user-menu-component',
        ) === null) {
          this.openMenu = '';
        }
      }
    },
    headerMenuButtonClicked() {
      this.sidebarTransitionDuration = 200;
      this.sidebarShowingOnNarrowScreens = !this.sidebarShowingOnNarrowScreens;
    },
    headerSearchShowButtonClicked() {
      this.headerSearchShowingOnNarrowScreens = true;

      this.$nextTick(() => {
        const headerSearchInput = document.getElementById('search-input') as HTMLInputElement;
        headerSearchInput.focus();
      });
    },
    async launchApp() {
      this.appStatus = 'launching';

      if (this.$store.state.drfToken === null) {
        this.setHtmlClass();
        this.appStatus = 'launched';
      } else {
        const responseData = await this.api({
          url: 'users/my_data/',
        });

        if (responseData.status === 200) {
          this.$store.commit('userData', responseData.body);
          this.$store.commit('loggedIn', true);
          this.appStatus = 'launched';
        } else {
          this.appStatus = 'error';
        }
      }
    },
    notificationsButtonClicked() {
      if (this.openMenu === 'notifications') {
        this.openMenu = '';
      } else {
        this.openMenu = 'notifications';
      }
    },
    async openChatRoom(chatRoomName: string) {
      this.openMenu = '';

      if (this.activeChatRoomName === chatRoomName) {
        return;
      }

      let proceed;

      if (this.activeChatRoomName === '') {
        proceed = true;
      } else {
        proceed = await (this.$refs.chatConversation as any).closeChat();
      }

      if (proceed) {
        this.activeChatRoomName = chatRoomName;
      }
    },
    setHtmlClass() {
      let classToAdd;
      let classToRemove;

      if (this.loggedIn && this.userData.signup_data === undefined) {
        classToAdd = 'is-authenticated';
        classToRemove = 'is-anonymous-or-not-onboarded';
      } else {
        classToAdd = 'is-anonymous-or-not-onboarded';
        classToRemove = 'is-authenticated';
      }

      document.documentElement.classList.remove(classToRemove);
      document.documentElement.classList.add(classToAdd);
    },
    userButtonClicked() {
      if (this.openMenu === 'user') {
        this.openMenu = '';
      } else {
        this.openMenu = 'user';
      }
    },
  },
});
</script>

<style lang="scss">
  :root {
    --gray-darkest: #202028;
    --gray-darker: #242731;
    --gray-dark: #282a38;
    --gray: #3b3f4b;
    --gray-a25: rgba(59, 63, 75, 0.25);
    --gray-a50: rgba(59, 63, 75, 0.5);
    --gray-light: #949496;
    --gray-light-a50: rgba(148, 148, 150, 0.5);
    --red-dark: #67222f;
    --red: #df2648;
    --red-a25: rgba(223, 38, 72, 0.25);
    --red-a50: rgba(223, 38, 72, 0.5);
    --yellow: #fbc335;
    --green-dark: #117449;
    --green: #0acd78;
    --blue-dark: #0965ce;
    --blue: #0078ff;
    --blue-a50: rgba(0, 120, 255, 0.5);
    --purple-dark: #56258d;
    --purple: #9142ec;

    --header-background-color: var(--gray-darkest);

    --is-authenticated-header-height: 74px;
    --horizontal-padding: 15px;
    --sidebar-width: 250px;
  }

  @import '~sweetalert2/src/variables';
  $swal2-background: var(--gray-darker);
  $swal2-title-color: #fff;
  $swal2-title-font-size: 1.6em;
  $swal2-content-color: #fff;
  $swal2-timer-progress-bar-background: var(--blue);
  $swal2-toast-box-shadow: 0 0 .625em var(--blue-dark);
  $swal2-toast-background: var(--gray-darker);
  @import '~sweetalert2/src/sweetalert2';

  .swal2-cancel {
    margin-left: 0.5rem;
  }

  @import '~v-tooltip/dist/v-tooltip';

  .vel-modal {
    background-color: #000;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body {
    background-color: var(--gray-darkest);
    color: #fff;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    line-height: 1.5;
    overflow-x: hidden;

    .is-authenticated & {
      overflow-y: scroll;
    }
  }

  a {
    color: var(--gray-light);
    text-decoration: none;
    transition: color 0.2s;

    &:hover {
      text-decoration: underline;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-top: 0;
    text-align: center;
  }

  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  .form-group:after {
    // We are using these three lines on the :after pseudo-element rather than
    // having a bottom margin on the actual element so that it plays well with
    // the "TransitionSlide" component

    content: "";
    display: block;
    height: 1rem;
  }

  label {
    display: inline-block;
    font-size: 13px;

    &:not(.form-check-label) {
      margin-bottom: 0.5rem;
    }
  }

  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="date"],
  input[type="time"],
  input[type="tel"],
  textarea {
    display: block;
    width: 100%;
    padding: 10px 16px;
    font-size: 14px;
    background-color: var(--gray-dark);
    border: 1px solid transparent;
    border-radius: 6px;
    color: #fff;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    --box-shadow-color: var(--gray-a25);

    .is-ios & {
      font-size: 16px;
    }

    &::placeholder {
      color: var(--gray-light);
    }

    &:focus {
      border-color: var(--gray);
      outline: 0;
      box-shadow: 0 0 0 0.2rem var(--box-shadow-color);
    }

    &.is-invalid {
      --box-shadow-color: var(--red-a25);
      border-color: var(--red);
    }
  }

  input[type="date"],
  input[type="time"] {
    &::-webkit-calendar-picker-indicator {
      filter: invert(1);
    }
  }

  input[type="file"] {
    display: block;
    max-width: 100%;
  }

  textarea {
    height: 64px;
  }

  .input-with-prefix {
    position: relative;

    input {
      padding-left: 32px;
    }
  }

  .input-prefix {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 12px;
    display: grid;
    place-content: center;
    width: 1em;
  }

  fieldset {
    border: 1px solid var(--gray);
    border-radius: 6px;

    &.is-invalid {
      border-color: var(--red);
    }

    .invalid-feedback {
      margin-top: 0;
      margin-bottom: 1rem;
    }

    .form-group:last-child:after {
      display: none;
    }
  }

  .form-check {
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5em;
    margin-bottom: 0.125rem;

    + .form-check {
      margin-top: 1rem;
    }

    .form-check-input {
      float: left;
      margin-left: -1.5em;
    }

    small {
      display: block;
      color: var(--gray-light);
    }
  }

  .form-check-input {
    width: 1em;
    height: 1em;
    margin-top: 0.25em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, 0.25);
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    -webkit-print-color-adjust: exact;
                  color-adjust: exact;
    --box-shadow-color: var(--gray-a25);

    &[type=checkbox] {
      border-radius: 0.25em;
    }

    &[type=radio] {
      border-radius: 50%;
    }

    &:active {
      filter: brightness(90%);
    }

    &:focus {
      border-color: var(--gray);
      outline: 0;
      box-shadow: 0 0 0 0.2rem var(--box-shadow-color);
    }

    &:checked {
      background-color: var(--blue);
      border-color: var(--blue);
    }

    &:checked[type=checkbox] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
    }

    &:checked[type=radio] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
    }

    &:disabled {
      pointer-events: none;
      filter: none;
      opacity: 0.5;
    }

    &[disabled] ~ .form-check-label,
    &:disabled ~ .form-check-label {
      opacity: 0.5;
    }

    &.is-invalid {
      border-color: var(--red);

      &:checked {
        background-color: var(--red);
      }

      &:focus {
        box-shadow: 0 0 0 0.25rem var(--red-a25);
      }
    }
  }

  .form-select {
    display: block;
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #fff;
    background-color: var(--gray-dark);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid transparent;
    border-radius: 6px;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    --box-shadow-color: var(--gray-a25);

    .is-ios & {
      font-size: 16px;
    }

    &:focus {
      border-color: var(--gray);
      outline: 0;
      box-shadow: 0 0 0 0.2rem var(--box-shadow-color);
    }

    &.is-invalid {
      --box-shadow-color: var(--red-a25);
      border-color: var(--red);
    }
  }

  .form-text {
    display: block;
    margin-top: 0.5rem;
  }

  .invalid-feedback {
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: var(--red);
  }

  .btn {
    display: inline-block;
    border: 1px solid transparent;
    padding: 10px 20px;
    line-height: 1.5;
    border-radius: 6px;
    cursor: pointer;
    transition:
      color 0.15s ease-in-out,
      background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;

    &:focus {
      outline: 0;
    }

    &:hover {
      text-decoration: none;
    }
  }

  .btn,
  .btn-transparent {
    &:disabled,
    &.disabled {
      pointer-events: none;
      opacity: 0.65;
    }
  }

  .btn + .btn {
    margin-left: 0.5rem;
  }

  .btn-xs {
    padding: 0.25rem;
    font-size: 0.7rem;
  }

  .btn-sm {
    padding: 0.15rem 0.5rem;
    font-size: 0.875rem;
  }

  .btn-lg {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
  }

  .btn-primary,
  .btn-outline-primary {
    border-color: var(--blue);

    &:focus {
      box-shadow: 0 0 0 0.2rem var(--blue-a50);
    }
  }

  .btn-primary {
    background-color: var(--blue);
    color: #fff;

    &:hover {
      border-color: var(--blue-dark);
      background-color: var(--blue-dark);
    }
  }

  .btn-outline-primary {
    background-color: transparent;
    color: var(--blue);

    &:hover {
      background-color: var(--blue);
      color: #fff;
    }
  }

  .btn-danger,
  .btn-outline-danger {
    border-color: var(--red);

    &:focus {
      box-shadow: 0 0 0 0.2rem var(--red-a50);
    }
  }

  .btn-danger {
    background-color: var(--red);
    color: #fff;

    &:hover {
      border-color: var(--red-dark);
      background-color: var(--red-dark);
    }
  }

  .btn-outline-danger {
    background-color: transparent;
    color: var(--red);

    &:hover {
      background-color: var(--red);
      color: #fff;
    }
  }

  .btn-light,
  .btn-outline-light {
    border-color: var(--gray-light);

    &:focus {
      box-shadow: 0 0 0 0.2rem var(--gray-light-a50);
    }
  }

  .btn-light {
    background-color: var(--gray-light);
    color: #fff;

    &:hover {
      border-color: var(--gray);
      background-color: var(--gray);
    }
  }

  .btn-outline-light {
    background-color: transparent;
    color: var(--gray-light);

    &:hover {
      background-color: var(--gray-light);
      color: #fff;
    }
  }

  .btn-transparent {
    padding: 0;
    line-height: inherit;
    color: inherit;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    cursor: pointer;
  }

  .card {
    padding: var(--horizontal-padding);
    background-color: var(--gray-darker);
    border-radius: 10px;
    word-wrap: break-word;
  }

  .navbar ul {
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 0;
    text-align: center;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: show;

    li {
      position: relative;
      display: inline-block;

      &:after {
        content: "";
        position: absolute;
        bottom: -15px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: var(--gray-dark);
      }

      + li {
        padding-left: 1.5rem;
      }
    }

    .router-link-exact-active {
      position: relative;
      color: #fff;

      &:after {
        content: "";
        position: absolute;
        bottom: -16px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: var(--blue);
        z-index: 1;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }

  .img-fluid {
    max-width: 100%;
    height: auto;
  }

  .horizontal-padding {
    padding-right: var(--horizontal-padding);
    padding-left: var(--horizontal-padding);
  }

  textarea,
  .navbar ul,
  .subtle-scrollbar {
    scrollbar-color: rgba(255, 255, 255, 0.2) transparent;

    &::-webkit-scrollbar {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.2);
      border: 3px solid transparent;
      background-clip: content-box;
      border-radius: 10px;

      &:hover,
      &:active {
        background-color: rgba(255, 255, 255, 0.25);
      }
    }

    &::-webkit-scrollbar-corner {
      background-color: transparent;
    }

    &::-webkit-resizer {
      border-width: 8px;
      border-style: solid;
      border-color: transparent rgba(255, 255, 255, 0.25) rgba(255, 255, 255, 0.25) transparent;
    }

    &:not(:hover) {
      &::-webkit-scrollbar-thumb {
        background-color: transparent;
      }
    }
  }

  .feather {
    vertical-align: middle;
  }

  .text-center {
    text-align: center;
  }

  .red-text {
    color: var(--red);
  }

  .green-text {
    color: var(--green);
  }

  .visually-hidden {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
  }

  .is-authenticated,
  .is-anonymous-or-not-onboarded {
    #app > div {
      min-height: 100vh;
    }
  }

  @supports (background: -webkit-named-image(i)) {
    // Safari only

    .is-authenticated,
    .is-anonymous-or-not-onboarded {
      #app > div {
        min-height: -webkit-fill-available;
      }
    }
  }

  .app-not-yet-launched-content {
    margin: 0 auto;
    padding-top: 1rem;
    max-width: 500px;
  }

  .is-authenticated {
    scroll-padding-top: var(--is-authenticated-header-height);

    #app > div {
      padding-bottom: 1rem;

      header {
        position: sticky;
        top: 0;
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: auto auto 1fr auto auto auto;
        align-items: center;
        padding: 10px var(--horizontal-padding);
        height: var(--is-authenticated-header-height);
        background-color: var(--header-background-color);
        z-index: 2;
      }
    }
  }

  .header-feed-link img {
    width: 32px;
  }

  .header-button {
    padding: 0.5rem;
    background-color: var(--gray-dark);
    border: 1px solid transparent;
    border-radius: 50%;
    line-height: 1;
    transition:
      background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;

    &[aria-expanded="true"] {
      background-color: var(--blue-dark);

      &:hover {
        background-color: var(--blue);
      }
    }

    @supports #{'\selector(*:focus-visible)'}  {
      &:focus {
        outline: 0;
      }

      &:focus-visible {
        border-color: var(--blue);
        box-shadow: 0 0 0 0.2rem var(--blue-a50);
      }
    }

    &:hover {
      background-color: var(--gray);
    }
  }

  .header-search-container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr auto;
    align-content: center;
    padding: 0 var(--horizontal-padding);
    background-color: var(--header-background-color);
    z-index: 2;

    .input-with-prefix {
      width: 100%;
      z-index: 1;
    }

    .input-prefix svg {
      stroke: var(--gray-light);
    }
  }

  .header-search-hide-button {
    align-self: center;
  }

  .header-button-unread-dot {
    position: absolute;
    top: -5px;
    right: -2px;
    width: 10px;
    height: 10px;
    background-color: var(--blue);
    border: 2px solid var(--gray-darker);
    border-radius: 50%;
  }

  .sidebar {
    background-image: url('./assets/header-bg.png');
    background-repeat: no-repeat;
    color: var(--gray-light);
    overflow-y: auto;

    ul {
      padding-left: 0;
      list-style: none;

      a {
        display: block;
        padding: 0.5rem 0;

        &:focus,
        &:hover {
          color: #fff;
        }

        &:hover {
          text-decoration: none;
        }

        svg {
          margin-right: 0.5em;
        }
      }
    }
  }

  .sidebar-feed-link {
    display: none;

    img {
      max-width: 200px;
    }
  }

  .sidebar-business {
    border-top: 1px solid var(--gray-darker);

    h2 {
      margin-top: 1.5rem;
      font-size: 12px;
      text-align: left;
      text-transform: uppercase;
    }
  }

  .ipg-registration-link {
    color: var(--blue);
  }

  .is-anonymous-or-not-onboarded #app > div {
    display: grid;
    grid-template-rows: auto 1fr auto;
    background-image: url('./assets/header-bg.png');
    background-repeat: no-repeat;

    header {
      padding: 4rem 0 1rem;

      ul {
        position: absolute;
        top: 0.4rem;
        right: 1rem;
        padding-left: 0;
        list-style: none;
      }

      li {
        display: inline-block;
        text-transform: uppercase;

        + li {
          margin-left: 1rem;
        }
      }
    }

    main {
      min-width: 0;
    }
  }

  .header-logo-link {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;

    img {
      width: 240px;
      height: 76px;
      vertical-align: middle;
    }
  }

  footer {
    padding: 0 var(--horizontal-padding);
    color: var(--gray-light);

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      margin-top: 1rem;
      padding: 2rem 0;
      border-top: 1px solid var(--gray-dark);
    }

    ul {
      margin: 0;
      padding-left: 0;
      list-style: none;
    }

    li {
      display: inline-block;

      + li {
        margin-left: 2rem;
      }
    }

    a {
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }
  }

  @media (min-width: 480px) {
    footer div {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  @media (min-width: 1024px) {
    :root {
      --horizontal-padding: 25px;
    }

    .is-authenticated #app > div {
      margin-left: var(--sidebar-width);

      header {
        grid-template-columns: 1fr auto auto auto;
      }
    }

    .header-feed-link,
    .header-menu-button,
    .header-search-show-button,
    .header-search-hide-button {
      display: none;
    }

    .header-search-container {
      position: static;
      display: block;
      padding: 0;
      width: 27rem;
      background-color: transparent;
    }

    .sidebar {
      display: block !important;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      padding-top: var(--horizontal-padding);
      width: var(--sidebar-width);
      border-right: 1px solid var(--gray-darker);
    }

    .sidebar-feed-link {
      display: block;
    }

    .header-logo-link {
      left: 7rem;
      transform: none;
    }
  }
</style>
