
import { defineComponent } from 'vue';
import {
  BellIcon,
  CheckIcon,
  SettingsIcon,
  Trash2Icon,
} from '@zhuowenli/vue-feather-icons';
import ProfileImage from '@/components/users/ProfileImage.vue';
import { NotificationInterface, NotificationWithStatusInterface } from '@/interfaces/notifications';

export default defineComponent({
  components: {
    BellIcon,
    CheckIcon,
    SettingsIcon,
    Trash2Icon,
    ProfileImage,
  },
  props: {
    buttonTabindex: {
      type: Number,
      default: 0,
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'buttonClicked',
  ],
  data: () => ({
    notificationsStatus: 'idle' as 'idle' | 'loading' | 'loaded' | 'error',
    notifications: {} as {
      next: string | null,
      previous: string | null,
      results: Array<NotificationWithStatusInterface>,
    },
    showHeaderButtonUnreadDot: false,
    markAllAsReadStatus: 'idle' as 'idle' | 'submitting' | 'error',
    socket: null as null | WebSocket,
    socketStatus: 'idle' as 'idle' | 'connected' | 'reconnecting' | 'disconnected',
    socketReconnectData: null as null | {
      attempt: number,
      countdown: number,
      intervalID: number,
    },
    wsServer: `${process.env.VUE_APP_API_URL}../ws/notifications/`
      .replace('http://', 'ws://')
      .replace('https://', 'wss://'),
  }),
  watch: {
    open() {
      if (this.open && this.notificationsStatus !== 'loading') {
        this.loadNotifications();
      }
    },
  },
  created() {
    this.connectToServer();
  },
  methods: {
    connectToServer() {
      if (this.socket !== null) {
        this.socketStatus = 'reconnecting';
      }

      if (this.socketReconnectData) {
        clearInterval(this.socketReconnectData.intervalID);
      }

      this.socket = new WebSocket(this.wsServer);

      this.socket.onopen = async () => {
        this.socketStatus = 'connected';
        this.socketReconnectData = null;

        this.sendOnWebsocket({
          type: 'login',
          authentication: `Token ${this.$store.state.drfToken}`,
        }, true);
      };

      this.socket.onmessage = async (event) => {
        const data = JSON.parse(event.data);

        if (data.type === 'login') {
          if (data.ok) {
            if (data.unseen_count && !this.open) {
              this.showHeaderButtonUnreadDot = true;
            }
          } else {
            this.logUserOut('reload');
          }
        } else if (data.type === 'delivery') {
          if (this.open) {
            this.loadNotifications();
          } else {
            this.showHeaderButtonUnreadDot = data.unseen_count > 0;
          }
        }
      };

      this.socket.onclose = (event) => {
        if (event.wasClean) {
          return;
        }

        if (this.socketReconnectData) {
          this.socketReconnectData.attempt += 1;
          this.socketReconnectData.countdown = this.socketReconnectData.attempt * 2;
        } else {
          this.socketReconnectData = {
            attempt: 1,
            countdown: 3,
            intervalID: 0,
          };
        }

        this.socketReconnectData.intervalID = setInterval(this.socketReconnectCountdown, 1000);
        this.socketStatus = 'disconnected';
      };
    },
    socketReconnectCountdown() {
      if (this.socketReconnectData) {
        this.socketReconnectData.countdown -= 1;

        if (!this.socketReconnectData.countdown) {
          clearInterval(this.socketReconnectData.intervalID);
          this.connectToServer();
        }
      }
    },
    sendOnWebsocket(data: any, json = true) {
      if (this.socket) {
        if (json) {
          // eslint-disable-next-line no-param-reassign
          data = JSON.stringify(data);
        }

        this.socket.send(data);
      }
    },
    async deleteNotification(notificationId: number) {
      this.setNotificationStatus(notificationId, 'deleting');

      const responseData = await this.api({
        url: `notifications/${notificationId}/`,
        method: 'DELETE',
      });

      if (responseData.status === 204) {
        this.notifications.results = this.notifications.results.filter(
          (n) => n.id !== notificationId,
        );
      } else {
        this.setNotificationStatus(notificationId, 'idle');
        const title = 'Failed to Delete Notification';
        let text;

        if (responseData.status === 404) {
          text = 'This notification no longer exists.';
        } else {
          text = 'Please check your connection and try again.';
        }

        this.$swal(title, text);
      }
    },
    getNotificationLink(notification: NotificationWithStatusInterface) {
      const to = {
        name: '',
        params: {} as Record<string, any>,
      };

      if (notification.notification_type === 'post_comment') {
        if (notification.data.team_slug === '') {
          to.name = 'user_comment';
          to.params.username = this.userData.username;
          to.params.postId = notification.object_id;
          to.params.commentId = notification.data.comment_id;
        } else {
          to.name = 'team_comment';
          to.params.teamSlug = notification.data.team_slug;
          to.params.postId = notification.object_id;
          to.params.commentId = notification.data.comment_id;
        }
      } else if (notification.notification_type === 'post_like') {
        if (notification.data.team_slug === '') {
          to.name = 'user_post';
          to.params.username = this.userData.username;
          to.params.postId = notification.object_id;
        } else {
          to.name = 'team_post';
          to.params.teamSlug = notification.data.team_slug;
          to.params.postId = notification.object_id;
        }
      } else if (notification.notification_type === 'comment_reply') {
        if (notification.data.team_slug === '') {
          to.name = 'user_comment';
          to.params.username = this.userData.username;
          to.params.postId = notification.data.post_id;
          to.params.commentId = notification.data.comment_id;
        } else {
          to.name = 'team_comment';
          to.params.teamSlug = notification.data.team_slug;
          to.params.postId = notification.data.post_id;
          to.params.commentId = notification.data.comment_id;
        }
      } else if (notification.notification_type === 'comment_like') {
        if (notification.data.team_slug === '') {
          to.name = 'user_comment';
          to.params.username = this.userData.username;
          to.params.postId = notification.data.post_id;
          to.params.commentId = notification.object_id;
        } else {
          to.name = 'team_comment';
          to.params.teamSlug = notification.data.team_slug;
          to.params.postId = notification.data.post_id;
          to.params.commentId = notification.object_id;
        }
      } else if (notification.notification_type === 'team_join_request_received') {
        to.name = 'team_join_requests';
        to.params.teamSlug = notification.data.team_slug;
      } else if (notification.notification_type === 'team_join_invitation_accepted') {
        to.name = 'team_members';
        to.params.teamSlug = notification.data.team_slug;
      } else if ([
        'team_join_invitation_declined',
        'team_join_invitation_received',
        'team_join_request_approved',
        'team_join_request_denied',
      ].includes(notification.notification_type)) {
        to.name = 'team';
        to.params.teamSlug = notification.data.team_slug;
      }

      return to;
    },
    getUserText(notification: NotificationWithStatusInterface) {
      let userText = `${notification.data.user.first_name} ${notification.data.user.last_name}`;

      if (notification.data.total_other_users) {
        userText += ` and ${notification.data.total_other_users} other`;

        if (notification.data.total_other_users > 1) {
          userText += 's';
        }
      }

      return userText;
    },
    async loadNotifications() {
      this.notificationsStatus = 'loading';

      const responseData = await this.api({
        url: 'notifications/',
      });

      if (responseData.status === 200) {
        const notifications = responseData.body;
        const modifiedResults = [] as Array<NotificationWithStatusInterface>;

        notifications.results.forEach((notification: NotificationInterface) => {
          const modifiedResult = JSON.parse(JSON.stringify(notification));
          modifiedResult.status = 'idle';
          modifiedResults.push(modifiedResult);
        });

        notifications.results = modifiedResults;
        this.notifications = notifications;
        this.notificationsStatus = 'loaded';
        this.showHeaderButtonUnreadDot = false;
      } else {
        this.notificationsStatus = 'error';
      }
    },
    async markAllAsRead() {
      this.markAllAsReadStatus = 'submitting';

      const responseData = await this.api({
        url: 'notifications/mark_all_as_read/',
        method: 'POST',
      });

      if (responseData.status === 204) {
        this.markAllAsReadStatus = 'idle';
        this.notifications.results.forEach((notification) => {
          // eslint-disable-next-line no-param-reassign
          notification.marked_as_read = 'yes';
        });
      } else {
        this.markAllAsReadStatus = 'error';
      }
    },
    notificationClickHandler(notification: NotificationWithStatusInterface) {
      if (!notification.clicked) {
        // eslint-disable-next-line no-param-reassign
        notification.clicked = 'yes';

        this.api({
          url: `notifications/${notification.id}/mark_as_clicked/`,
          method: 'PATCH',
        });
      }
    },
    setNotificationStatus(notificationId: number, status: 'idle' | 'toggling_read_status' | 'deleting') {
      const notifications = this.notifications.results;
      const notification = notifications.filter((n) => n.id === notificationId)[0];
      notification.status = status;
    },
    async toggleNotificationReadStatus(notification: NotificationWithStatusInterface) {
      this.setNotificationStatus(notification.id, 'toggling_read_status');

      const json = { read: true };

      if (notification.clicked || notification.marked_as_read) {
        json.read = false;
      }

      const responseData = await this.api({
        url: `notifications/${notification.id}/change_read_status/`,
        method: 'PATCH',
        json,
      });

      this.setNotificationStatus(notification.id, 'idle');

      if (responseData.status === 204) {
        if (json.read) {
          // eslint-disable-next-line no-param-reassign
          notification.marked_as_read = 'yes';
        } else {
          // eslint-disable-next-line no-param-reassign
          notification.marked_as_read = null;
          // eslint-disable-next-line no-param-reassign
          notification.clicked = null;
        }
      } else {
        const title = `Failed to Mark Notification as ${json.read ? 'Read' : 'Unread'}`;
        let text;

        if (responseData.status === 404) {
          text = 'This notification no longer exists.';
        } else {
          text = 'Please check your connection and try again.';
        }

        this.$swal(title, text);
      }
    },
  },
});
