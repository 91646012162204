import { UserWithChatRoomIdInterface } from '@/interfaces/users';

export default function getChatRoomMemberNames(members: UserWithChatRoomIdInterface[]): string {
  const totalMembers = members.length;
  const firstMemberName = `${members[0].first_name} ${members[0].last_name}`;

  if (totalMembers === 1) {
    return firstMemberName;
  }

  if (totalMembers === 2) {
    return `${firstMemberName} and ${members[1].first_name} ${members[1].last_name}`;
  }

  let retval = '';

  members.forEach((member, i) => {
    retval += `${member.first_name} ${member.last_name}`;

    if (i <= totalMembers - 2) {
      retval += ', ';
    }

    if (i === totalMembers - 2) {
      retval += 'and ';
    }
  });

  return retval;
}
