
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import { MessageCircleIcon } from '@zhuowenli/vue-feather-icons';
import ProfileImage from '@/components/users/ProfileImage.vue';
import { ChatRoomInterface, ConversationInterface } from '@/interfaces/chat';
import { UserWithChatRoomIdInterface } from '@/interfaces/users';
import getChatRoomMemberNames from '@/methods/get_chat_room_member_names';

export default defineComponent({
  components: {
    MessageCircleIcon,
    ProfileImage,
  },
  props: {
    buttonTabindex: {
      type: Number,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'buttonClicked',
    'chatRoomClicked',
  ],
  data: () => ({
    chatRoomsStatus: 'idle' as 'idle' | 'loading' | 'loaded' | 'error',
    chatRooms: {} as {
      results: Array<ChatRoomInterface>,
    },
    memberFirstNames: {} as Record<number, string>,
    markAllAsReadStatus: 'idle' as 'idle' | 'submitting' | 'error',
  }),
  computed: {
    ...mapState([
      'timestampKeySuffix',
    ]),
    unreadPrivateMessages(): Array<ConversationInterface> {
      return (this.$root as any).chatConversations.filter((c: ConversationInterface) => !c.read);
    },
  },
  watch: {
    open() {
      if (this.open && this.chatRoomsStatus !== 'loading') {
        this.loadChatRooms();
      }
    },
    unreadPrivateMessages() {
      if (this.unreadPrivateMessages.length && this.open && this.chatRoomsStatus !== 'loading') {
        this.loadChatRooms();
      }
    },
  },
  methods: {
    getChatRoomMemberNames,
    async loadChatRooms() {
      this.chatRoomsStatus = 'loading';

      const responseData = await this.api({
        url: 'chat/rooms/has_messages/',
      });

      if (responseData.status === 200) {
        if (responseData.body.length) {
          const memberFirstNames = {} as Record<number, string>;
          memberFirstNames[this.userData.id] = 'You';

          responseData.body.forEach((chatRoom: ChatRoomInterface) => {
            chatRoom.other_members.forEach((otherMember: UserWithChatRoomIdInterface) => {
              memberFirstNames[otherMember.chat_room_id] = otherMember.first_name;
            });
          });

          this.memberFirstNames = memberFirstNames;
        }

        this.chatRooms = { results: responseData.body };
        this.chatRoomsStatus = 'loaded';
      } else {
        this.chatRoomsStatus = 'error';
      }
    },
  },
});
