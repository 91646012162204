<template>
  <div
    id="chat-component"
    class="component"
  >
    <button
      type="button"
      class="btn-transparent header-button"
      aria-controls="chat-menu"
      aria-label="Toggle chat menu"
      :aria-expanded="open"
      :tabindex="buttonTabindex"
      @click="$emit('buttonClicked')"
    >
      <div class="button-content">
        <message-circle-icon />
        <div
          v-if="unreadPrivateMessages.length"
          class="header-button-unread-dot"
        />
      </div>
    </button>

    <div
      v-show="open"
      id="chat-menu"
      class="menu"
    >
      <div class="menu-header">
        <h2>Chats</h2>

        <spinner
          v-if="chatRoomsStatus === 'loading'"
          center
        />

        <template v-else-if="chatRoomsStatus === 'error'">
          <alert variant="danger">
            Unable to load your chats. Please check your connection and try
            again.
          </alert>

          <button
            type="button"
            class="btn btn-outline-primary"
            @click="loadChatRooms"
          >
            Try Again
          </button>
        </template>

        <p
          v-else-if="chatRoomsStatus === 'loaded' && chatRooms.results.length === 0"
          class="no-chat-rooms"
        >
          You have no chats.
        </p>
      </div>

      <div
        v-if="chatRoomsStatus === 'loaded' && chatRooms.results.length"
        class="chat-rooms subtle-scrollbar"
      >
        <button
          v-for="chatRoom of chatRooms.results"
          :key="`chatRoom${chatRoom.name}`"
          type="button"
          :class="
            `btn-transparent
            chat-room
            chat-room-${!chatRoom.latest_message.read ? 'un' : ''}read`
          "
          @click="$emit('chatRoomClicked', chatRoom.name)"
        >
          <div
            class="profile-images"
            :data-total-other-members="chatRoom.other_members.length"
          >
            <profile-image
              v-if="chatRoom.other_members.length === 1"
              :user="chatRoom.other_members[0]"
            />
            <template v-else>
              <profile-image
                v-for="member of chatRoom.other_members.slice(0, 4)"
                :key="`profileImage${member.chat_room_id}`"
                :user="member"
                :size="24"
              />
            </template>
          </div>

          <div>
            <div class="member-names">
              {{ getChatRoomMemberNames(chatRoom.other_members) }}
            </div>

            <div class="latest-message-body">
              <template
                v-if="
                  chatRoom.latest_message.sender_id === userData.id
                    || chatRoom.other_members.length > 1
                "
              >
                {{ memberFirstNames[chatRoom.latest_message.sender_id] }}:
              </template>

              <template v-if="chatRoom.latest_message.msg_type === 'text'">
                {{ chatRoom.latest_message.message }}
              </template>
              <template v-else-if="chatRoom.latest_message.msg_type === 'image'">
                [Image]
              </template>
              <template v-else>
                [Video]
              </template>
            </div>

            <div
              :key="`timestamp${timestampKeySuffix}`"
              class="latest-message-timestamp"
            >
              {{ timestampDistanceDisplay(chatRoom.latest_message.timestamp) }}
            </div>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import { MessageCircleIcon } from '@zhuowenli/vue-feather-icons';
import ProfileImage from '@/components/users/ProfileImage.vue';
import { ChatRoomInterface, ConversationInterface } from '@/interfaces/chat';
import { UserWithChatRoomIdInterface } from '@/interfaces/users';
import getChatRoomMemberNames from '@/methods/get_chat_room_member_names';

export default defineComponent({
  components: {
    MessageCircleIcon,
    ProfileImage,
  },
  props: {
    buttonTabindex: {
      type: Number,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'buttonClicked',
    'chatRoomClicked',
  ],
  data: () => ({
    chatRoomsStatus: 'idle' as 'idle' | 'loading' | 'loaded' | 'error',
    chatRooms: {} as {
      results: Array<ChatRoomInterface>,
    },
    memberFirstNames: {} as Record<number, string>,
    markAllAsReadStatus: 'idle' as 'idle' | 'submitting' | 'error',
  }),
  computed: {
    ...mapState([
      'timestampKeySuffix',
    ]),
    unreadPrivateMessages(): Array<ConversationInterface> {
      return (this.$root as any).chatConversations.filter((c: ConversationInterface) => !c.read);
    },
  },
  watch: {
    open() {
      if (this.open && this.chatRoomsStatus !== 'loading') {
        this.loadChatRooms();
      }
    },
    unreadPrivateMessages() {
      if (this.unreadPrivateMessages.length && this.open && this.chatRoomsStatus !== 'loading') {
        this.loadChatRooms();
      }
    },
  },
  methods: {
    getChatRoomMemberNames,
    async loadChatRooms() {
      this.chatRoomsStatus = 'loading';

      const responseData = await this.api({
        url: 'chat/rooms/has_messages/',
      });

      if (responseData.status === 200) {
        if (responseData.body.length) {
          const memberFirstNames = {} as Record<number, string>;
          memberFirstNames[this.userData.id] = 'You';

          responseData.body.forEach((chatRoom: ChatRoomInterface) => {
            chatRoom.other_members.forEach((otherMember: UserWithChatRoomIdInterface) => {
              memberFirstNames[otherMember.chat_room_id] = otherMember.first_name;
            });
          });

          this.memberFirstNames = memberFirstNames;
        }

        this.chatRooms = { results: responseData.body };
        this.chatRoomsStatus = 'loaded';
      } else {
        this.chatRoomsStatus = 'error';
      }
    },
  },
});
</script>

<style lang="scss" scoped>
  .component {
    position: relative;
  }

  .button-content {
    position: relative;
  }

  .feather-message-circle {
    stroke: var(--gray-light);
  }

  .menu {
    position: absolute;
    right: -120px;
    top: calc(100% + 6px);
    width: 290px;
    background-color: var(--gray-darker);
    border-radius: 10px;
    box-shadow: 0 0 2px 4px var(--gray-a50);
  }

  .menu-header {
    padding: 15px;
  }

  .no-chat-rooms {
    margin-bottom: 0;
    text-align: center;
  }

  .chat-rooms {
    max-height: 54.57vh;
    border-top: 1px solid var(--gray);
    overflow-y: auto;
    overscroll-behavior-y: contain;
  }

  .chat-room {
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: 48px 1fr;
    align-items: center;
    padding: 15px;
    width: 100%;
    text-align: left;
    color: #fff;
    transition: background-color 0.15s ease-in-out;

    &:focus,
    &:hover {
      background-color: var(--gray-dark);
    }

    &:focus {
      outline: 0;
    }

    &:hover {
      text-decoration: none;
    }

    > div:last-child {
      min-width: 0;
    }

    + .chat-room {
      border-top: 1px solid var(--gray);
    }
  }

  .chat-room-unread {
    .latest-message-body {
      color: #fff;
    }

    .latest-message-timestamp {
      color: var(--blue);
    }
  }

  .profile-images {
    &:not([data-total-other-members="1"]) {
      display: grid;
      grid-template-columns: 24px 24px;
    }

    &[data-total-other-members="2"] {
      :first-child {
        grid-column: 1 / -1;
      }

      :last-child {
        grid-column: 2;
      }
    }

    &[data-total-other-members="3"] {
      justify-items: center;

      :first-child {
        grid-column: 1 / -1;
      }
    }
  }

  .member-names,
  .latest-message-body {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .latest-message-body,
  .latest-message-timestamp {
    font-size: 80%;
    color: var(--gray-light);
  }

  @media (min-width: 480px) {
    .menu {
      right: 0;
    }
  }
</style>
