<template>
  <svg
    v-show="show"
    :width="sizeToUse"
    :height="sizeToUse"
    :style="spinnerStyle"
    viewBox="0 0 38 38"
    xmlns="http://www.w3.org/2000/svg"
    class="spinner"
  >
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <g transform="translate(1 1)">
        <path
          d="M36 18c0-9.94-8.06-18-18-18"
          :stroke="colorToUse"
          :stroke-width="thicknessToUse"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 18 18"
            to="360 18 18"
            :dur="`${speedToUse}s`"
            repeatCount="indefinite"
          />
        </path>
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    size: {
      type: Number,
      default: 16,
    },
    thickness: {
      type: Number,
      default: 1.5,
    },
    speed: {
      type: Number,
      default: 0.7,
    },
    center: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '#fff',
    },
    preset: {
      type: String,
      default: '',
    },
  },
  data() {
    let center;
    let color;
    let size;
    let thickness;
    let speed;

    if (this.preset === 'large') {
      center = true;
      size = 64;
      ({ color, thickness, speed } = this);
    } else {
      ({
        center, color, size, thickness, speed,
      } = this);
    }

    return {
      centerToUse: center,
      colorToUse: color,
      sizeToUse: size,
      thicknessToUse: thickness,
      speedToUse: speed,
    };
  },
  computed: {
    spinnerStyle(): { [key: string]: string } {
      return {
        display: this.centerToUse ? 'block' : 'inline-block',
        margin: this.centerToUse ? '0 auto' : '0',
      };
    },
  },
});
</script>

<style scoped>
  .spinner {
    vertical-align: text-bottom;
  }
</style>
