import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import store from '../store';

const routes: Array<RouteRecordRaw> = [
  // The following route is only needed in development
  {
    path: '/',
    redirect: { name: 'feed' },
  },
  {
    path: '/ids',
    redirect: { name: 'document', params: { documentSlug: 'income-disclosure' } },
  },
  {
    path: '/login',
    name: 'login',
    meta: { allowAnonymous: true },
    component: () => import(/* webpackChunkName: "login" */ '../views/users/Login.vue'),
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import(/* webpackChunkName: "logout" */ '../views/users/Logout.vue'),
  },
  {
    path: '/password-reset',
    name: 'password_reset',
    meta: { allowAnonymous: true },
    component: () => import(/* webpackChunkName: "password_reset" */ '../views/users/PasswordReset.vue'),
  },
  {
    path: '/password-reset/:uidb64/:token',
    name: 'password_reset_confirm',
    meta: { allowAnonymous: true },
    props: true,
    component: () => import(/* webpackChunkName: "password_reset_confirm" */ '../views/users/PasswordResetConfirm.vue'),
  },
  {
    path: '/signup/:username?',
    name: 'signup',
    meta: { allowAnonymous: true },
    props: true,
    component: () => import(/* webpackChunkName: "signup" */ '../views/users/SignUp.vue'),
  },
  {
    path: '/verify-email/:token',
    name: 'verify_email',
    meta: { allowAnonymous: true },
    props: true,
    component: () => import(/* webpackChunkName: "verify_email" */ '../views/users/VerifyEmail.vue'),
  },
  {
    path: '/onboard',
    name: 'onboard',
    component: () => import(/* webpackChunkName: "onboard" */ '../views/users/Onboard.vue'),
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
  },
  {
    path: '/business-portal/documents',
    name: 'ipg_documents',
    props: true,
    component: () => import(/* webpackChunkName: "ipg_documents" */ '../views/business_portal/Documents.vue'),
  },
  {
    path: '/business-portal/downline/:matrixPositionId(\\d+)?',
    name: 'ipg_downline',
    props(route) {
      const props = { ...route.params };
      if (props.matrixPositionId === undefined) {
        props.matrixPositionId = '';
      }
      // @ts-expect-error Type 'number' is not assignable to type 'string | string[]'.
      props.matrixPositionId = +props.matrixPositionId;
      return props;
    },
    component: () => import(/* webpackChunkName: "ipg_downline" */ '../views/business_portal/Downline.vue'),
  },
  {
    path: '/business-portal/ewallet/transactions',
    name: 'ipg_ewallet_transactions',
    props(route) {
      const props = { ...route.params };
      props.activeTab = 'transactions';
      return props;
    },
    component: () => import(/* webpackChunkName: "ipg_ewallet_transactions" */ '../views/business_portal/Ewallet.vue'),
  },
  {
    path: '/business-portal/invitations',
    name: 'ipg_invitations',
    component: () => import(/* webpackChunkName: "ipg_invitations" */ '../views/business_portal/Invitations.vue'),
  },
  {
    path: '/business-portal/mentorship',
    name: 'ipg_mentorship',
    component: () => import(/* webpackChunkName: "ipg_mentorship" */ '../views/business_portal/Mentorship.vue'),
  },
  {
    path: '/business-portal/training',
    name: 'ipg_training_index',
    props: true,
    component: () => import(/* webpackChunkName: "ipg_training" */ '../views/business_portal/TrainingIndex.vue'),
  },
  {
    path: '/business-portal/training/:trainingDocumentSlug',
    name: 'ipg_training',
    props: true,
    component: () => import(/* webpackChunkName: "ipg_training" */ '../views/business_portal/Training.vue'),
  },
  {
    path: '/events',
    name: 'events',
    component: () => import(/* webpackChunkName: "events" */ '../views/Events.vue'),
  },
  {
    path: '/feed',
    name: 'feed',
    component: () => import(/* webpackChunkName: "feed" */ '../views/Feed.vue'),
  },
  {
    path: '/game/:gameSlug',
    name: 'game',
    props(route) {
      const props = { ...route.params };
      props.activeTab = 'info';
      return props;
    },
    component: () => import(/* webpackChunkName: "game" */ '../views/games/Game.vue'),
  },
  {
    path: '/game/:gameSlug/players-casual',
    name: 'game_players_casual',
    props(route) {
      const props = { ...route.params };
      props.activeTab = 'players_casual';
      return props;
    },
    component: () => import(/* webpackChunkName: "game_players_casual" */ '../views/games/Game.vue'),
  },
  {
    path: '/game/:gameSlug/players-competitive',
    name: 'game_players_competitive',
    props(route) {
      const props = { ...route.params };
      props.activeTab = 'players_competitive';
      return props;
    },
    component: () => import(/* webpackChunkName: "game_players_competitive" */ '../views/games/Game.vue'),
  },
  {
    path: '/game/:gameSlug/coaches',
    name: 'game_coaches',
    props(route) {
      const props = { ...route.params };
      props.activeTab = 'coaches';
      return props;
    },
    component: () => import(/* webpackChunkName: "game_coaches" */ '../views/games/Game.vue'),
  },
  {
    path: '/game/:gameSlug/teams',
    name: 'game_teams',
    props(route) {
      const props = { ...route.params };
      props.activeTab = 'teams';
      return props;
    },
    component: () => import(/* webpackChunkName: "game_teams" */ '../views/games/Game.vue'),
  },
  {
    path: '/documents/:documentSlug',
    name: 'document',
    meta: { allowAnonymous: true },
    props: true,
    component: () => import(/* webpackChunkName: "document" */ '../views/Document.vue'),
  },
  {
    path: '/settings/account',
    name: 'settings_account',
    props(route) {
      const props = { ...route.params };
      props.activeTab = 'account';
      return props;
    },
    component: () => import(/* webpackChunkName: "settings_account" */ '../views/users/Settings.vue'),
  },
  {
    path: '/settings/images',
    name: 'settings_images',
    props(route) {
      const props = { ...route.params };
      props.activeTab = 'images';
      return props;
    },
    component: () => import(/* webpackChunkName: "settings_images" */ '../views/users/Settings.vue'),
  },
  {
    path: '/settings/notifications',
    name: 'settings_notifications',
    props(route) {
      const props = { ...route.params };
      props.activeTab = 'notifications';
      return props;
    },
    component: () => import(/* webpackChunkName: "settings_notifications" */ '../views/users/Settings.vue'),
  },
  {
    path: '/settings/subscription',
    name: 'settings_subscription',
    props(route) {
      const props = { ...route.params };
      props.activeTab = 'subscription';
      return props;
    },
    component: () => import(/* webpackChunkName: "settings_subscription" */ '../views/users/Settings.vue'),
  },
  {
    path: '/settings/ipg',
    name: 'settings_ipg',
    props(route) {
      const props = { ...route.params };
      props.activeTab = 'ipg';
      return props;
    },
    component: () => import(/* webpackChunkName: "settings_ipg" */ '../views/users/Settings.vue'),
  },
  {
    path: '/settings/third-party-accounts',
    name: 'settings_third_party_accounts',
    props(route) {
      const props = { ...route.params };
      props.activeTab = 'third_party_accounts';
      return props;
    },
    component: () => import(/* webpackChunkName: "settings_third_party_accounts" */ '../views/users/Settings.vue'),
  },
  {
    path: '/team/:teamSlug',
    name: 'team',
    meta: { allowAnonymous: true },
    props(route) {
      const props = { ...route.params };
      props.activeTab = 'posts';
      return props;
    },
    component: () => import(/* webpackChunkName: "team" */ '../views/teams/Team.vue'),
  },
  {
    path: '/team/:teamSlug/:postId(\\d+)',
    name: 'team_post',
    meta: { allowAnonymous: true },
    props(route) {
      const props = { ...route.params };
      // @ts-expect-error Type 'number' is not assignable to type 'string | string[]'.
      props.postId = +props.postId;
      props.activeTab = 'post';
      return props;
    },
    component: () => import(/* webpackChunkName: "team_post" */ '../views/teams/Team.vue'),
  },
  {
    path: '/team/:teamSlug/:postId(\\d+)/:commentId(\\d+)',
    name: 'team_comment',
    meta: { allowAnonymous: true },
    props(route) {
      const props = { ...route.params };
      // @ts-expect-error Type 'number' is not assignable to type 'string | string[]'.
      props.postId = +props.postId;
      // @ts-expect-error Type 'number' is not assignable to type 'string | string[]'.
      props.commentId = +props.commentId;
      props.activeTab = 'comment';
      return props;
    },
    component: () => import(/* webpackChunkName: "team_comment" */ '../views/teams/Team.vue'),
  },
  {
    path: '/team/:teamSlug/calendar',
    name: 'team_calendar',
    props(route) {
      const props = { ...route.params };
      props.activeTab = 'calendar';
      return props;
    },
    component: () => import(/* webpackChunkName: "team_calendar" */ '../views/teams/Team.vue'),
  },
  {
    path: '/team/:teamSlug/info',
    name: 'team_info',
    meta: { allowAnonymous: true },
    props(route) {
      const props = { ...route.params };
      props.activeTab = 'info';
      return props;
    },
    component: () => import(/* webpackChunkName: "team_info" */ '../views/teams/Team.vue'),
  },
  {
    path: '/team/:teamSlug/members',
    name: 'team_members',
    props(route) {
      const props = { ...route.params };
      props.activeTab = 'members-current';
      return props;
    },
    component: () => import(/* webpackChunkName: "team_members" */ '../views/teams/Team.vue'),
  },
  {
    path: '/team/:teamSlug/members/join-requests',
    name: 'team_join_requests',
    props(route) {
      const props = { ...route.params };
      props.activeTab = 'members-join_requests';
      return props;
    },
    component: () => import(/* webpackChunkName: "team_join_requests" */ '../views/teams/Team.vue'),
  },
  {
    path: '/team/:teamSlug/members/invitations-sent',
    name: 'team_invitations_sent',
    props(route) {
      const props = { ...route.params };
      props.activeTab = 'members-invitations_sent';
      return props;
    },
    component: () => import(/* webpackChunkName: "team_invitations_sent" */ '../views/teams/Team.vue'),
  },
  {
    path: '/team/:teamSlug/settings',
    name: 'team_settings',
    props(route) {
      const props = { ...route.params };
      props.activeTab = 'settings-basic';
      return props;
    },
    component: () => import(/* webpackChunkName: "team_settings" */ '../views/teams/Team.vue'),
  },
  {
    path: '/team/:teamSlug/settings/cover-image',
    name: 'team_cover_image',
    props(route) {
      const props = { ...route.params };
      props.activeTab = 'settings-cover_image';
      return props;
    },
    component: () => import(/* webpackChunkName: "team_cover_image" */ '../views/teams/Team.vue'),
  },
  {
    path: '/team/:teamSlug/delete',
    name: 'delete_team',
    props(route) {
      const props = { ...route.params };
      props.activeTab = 'settings-delete';
      return props;
    },
    component: () => import(/* webpackChunkName: "delete_team" */ '../views/teams/Team.vue'),
  },
  {
    path: '/teams/my',
    name: 'my_teams',
    props(route) {
      const props = { ...route.params };
      props.activeTab = 'my_teams';
      return props;
    },
    component: () => import(/* webpackChunkName: "my_teams" */ '../views/teams/Teams.vue'),
  },
  {
    path: '/teams/other',
    name: 'other_teams',
    props(route) {
      const props = { ...route.params };
      props.activeTab = 'other_teams';
      return props;
    },
    component: () => import(/* webpackChunkName: "other_teams" */ '../views/teams/Teams.vue'),
  },
  {
    path: '/teams/create',
    name: 'create_team',
    props(route) {
      const props = { ...route.params };
      props.activeTab = 'create_team';
      return props;
    },
    component: () => import(/* webpackChunkName: "create_team" */ '../views/teams/Teams.vue'),
  },
  {
    path: '/user/:username',
    name: 'user_profile',
    meta: { allowAnonymous: true },
    props(route) {
      const props = { ...route.params };
      props.activeTab = 'posts';
      return props;
    },
    component: () => import(/* webpackChunkName: "user_profile" */ '../views/users/Profile.vue'),
  },
  {
    path: '/user/:username/info',
    name: 'user_info',
    props(route) {
      const props = { ...route.params };
      props.activeTab = 'info';
      return props;
    },
    component: () => import(/* webpackChunkName: "user_info" */ '../views/users/Profile.vue'),
  },
  {
    path: '/user/:username/games/:listType(casual|competitive|coach)',
    name: 'user_games',
    props(route) {
      const props = { ...route.params };
      props.activeTab = `games_${props.listType}`;
      delete props.listType;
      return props;
    },
    component: () => import(/* webpackChunkName: "user_games" */ '../views/users/Profile.vue'),
  },
  {
    path: '/user/:username/:postId(\\d+)',
    name: 'user_post',
    meta: { allowAnonymous: true },
    props(route) {
      const props = { ...route.params };
      // @ts-expect-error Type 'number' is not assignable to type 'string | string[]'.
      props.postId = +props.postId;
      props.activeTab = 'post';
      return props;
    },
    component: () => import(/* webpackChunkName: "user_post" */ '../views/users/Profile.vue'),
  },
  {
    path: '/user/:username/:postId(\\d+)/:commentId(\\d+)',
    name: 'user_comment',
    meta: { allowAnonymous: true },
    props(route) {
      const props = { ...route.params };
      // @ts-expect-error Type 'number' is not assignable to type 'string | string[]'.
      props.postId = +props.postId;
      // @ts-expect-error Type 'number' is not assignable to type 'string | string[]'.
      props.commentId = +props.commentId;
      props.activeTab = 'comment';
      return props;
    },
    component: () => import(/* webpackChunkName: "user_comment" */ '../views/users/Profile.vue'),
  },
  {
    path: '/calendar',
    name: 'my_calendar',
    component: () => import(/* webpackChunkName: "my_calendar" */ '../views/users/Calendar.vue'),
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    meta: { allowAnonymous: true },
    component: () => import(/* webpackChunkName: "404" */ '../views/PageNotFound.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    return {
      top: 0,
      left: 0,
    };
  },
});

router.beforeEach((to, from, next) => {
  store.commit('latestFromRoute', from);
  window.app.sidebarShowingOnNarrowScreens = false;

  if (to.matched.some((record) => record.meta.allowAnonymous)) {
    next();
  } else if (store.state.drfToken === null) {
    next({
      name: 'login',
      query: { next: to.fullPath },
    });
  } else {
    next();
  }
});

export default router;
